<template>
  <div class="header d-flex flex-wrap flex-md-nowrap">
    <div
      v-for="c in columnsWithNames"
      :key="c.key"
      class="column-text"
      :class="{
        'px-1': c.key !== 'space',
        'pr-2': c.key === 'price',
        [c.key]: true,
        [c.type]: true
      }"
      :style="c.width
        ? { width:`${c.width}px`, minWidth: `${c.width}px` }
        : {}"
    >
      <span v-if="c.key === 'space'">
        &nbsp;
      </span>
      <span
        v-else
        class="text-nowrap"
      >
        <i :class="c.icon" />
        {{ c.name }}
      </span>
    </div>
  </div>
</template>

<script>
import ordersColumns from '@/pages/shop/order/columns';

export default {
  props: {
    columns: Array,
  },
  data: () => ({
    ordersColumns,
  }),
  computed: {
    columnsWithNames() {
      return this.columns.map((c) => {
        const orderColumn = this.ordersColumns.find((x) => x.key === c.key);
        if (!orderColumn) return c;
        return {
          ...c,
          ...orderColumn,
        };
      });
    },
  },
  methods: {
    getPropertyName(id) {
      return this.properties.find((x) => x.code === id)?.name;
    },
    getGroupName(id) {
      return this.groups.find((x) => x.code === id)?.path;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.group {
  width: 50px;
  min-width: 50px;
}
.property {
  width: 150px;
  min-width: 150px;
}

.aggregatedPrice {
  text-align: right;
}

.aggregatedCount {
  padding-left: 10px !important;
}

.aggregatedPrice {
  padding-right: 10px !important;
  margin-right: 10px !important;
}

.header {
  color: #666;
  padding-left: 33px;
  align-items: center;
  height: 32px;

  .column-text {
    font-size: 0.7rem !important;
  }
}
</style>
