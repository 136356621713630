<template>
  <div
    class="d-flex align-items-center"
  >
    <div
      v-if="!noCheckbox"
      class="checkbox set-row set-row-hover"
      :class="{ last }"
      @click="$emit('toggle')"
    >
      <Checkbox
        :value="checked"
        :emit="false"
      />
    </div>

    <div
      class="d-flex flex-wrap flex-md-nowrap set-row flex-grow-1 set-row-hover"
      :class="{ selected, last }"
      @click="$emit('select')"
    >
      <template v-for="c in columnsWithNames">
        <div
          v-if="c.key === 'day'"
          :key="c.key"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
          class="money-font small font-weight-normal px-1"
        >
          {{ singleOrderSchema ? "" : order.formattedDay }}
        </div>

        <div
          v-else-if="c.key === 'name'"
          :key="c.key"
          :class="c.key"
          class="flex-grow-1 px-1 w-100"
        >
          <PayerName
            v-bind="payer"
            no-code
          />
        </div>
        <div
          v-else-if="c.key === 'code'"
          :key="c.key"
          v-tippy="{
            trigger: (payer.code || '').length > 15 ? 'mouseenter' : 'manual',
            delay: 500
          }"
          :class="c.key"
          :content="payer.code"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
          class="text-secondary align-self-center small px-1 text-truncate"
        >
          {{ payer.code }}
        </div>
        <div
          v-else-if="c.type === 'property'"
          :key="c.key"
          style="width: 150px"
          class="px-1 small offer-name"
        >
          {{ payer.properties[c.key] }}
        </div>
        <div
          v-else-if="c.type === 'group'"
          :key="c.key"
          style="width: 50px"
          class="px-1"
        >
          <i
            v-if="payer.groups.includes(c.key)"
            class="fas fa-square-check text-primary"
          />
          <i
            v-else
            class="fa-solid fa-square check uncheck"
          />
        </div>

        <span
          v-else-if="c.key === 'productSetName'"
          :key="c.key"
          :class="c.key"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
          class="offer-name px-1"
        >
          {{ order.set.name }}
        </span>

        <span
          v-else-if="c.key === 'aggregatedPrice'"
          :key="c.key"
          :class="c.key"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
          class="aggregation px-1 money-font text-right"
        >
          {{ formatCurrency(order.totalPrice, order.currency) }}
        </span>
        <span
          v-else-if="c.key === 'aggregatedCount'"
          :key="c.key"
          :class="c.key"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
          class="aggregation px-1 money-font text-right"
        >
          <i class="far fa-box mr-2" />
          x{{ order.totalCount }}
        </span>

        <span
          v-else-if="c.key === 'status'"
          :key="c.key"
          :class="c.key"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
          class="status-name px-1"
        >
          <span
            class="badge px-3"
            :class="order.status ? 'text-white' : ''"
            :style="{ backgroundColor: order.status ? colors(order.status.color) : 'transparent' }"
          >
            {{ order.status ? order.status.name : '-' }}
          </span>
        </span>

        <span
          v-else-if="c.key === 'created'"
          :key="c.key"
          :class="c.key"
          class="px-1 date"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
        >
          {{ formatDate(order.created) }}
        </span>

        <div
          v-else-if="c.key === 'paid'"
          :key="c.key"
          :class="c.key"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
          class="px-1"
        >
          <span
            v-if="order.isPaid"
            class="text-success small text-uppercase"
          >
            <span
              class="fa-stack fa-1x"
              style="width: 18px"
            >
              <i class="far fa-circle-dollar fa-stack-1x" />
            </span>

            {{ $t('general.yes') }}
          </span>
          <span
            v-else
            class="text-danger small text-uppercase"
          >
            <span
              class="fa-stack fa-1x"
              style="width: 18px"
            >
              <i class="far fa-circle-dollar fa-stack-1x" />
              <i class="far fa-slash fa-stack-1x" />
            </span>
            <span>
              {{ $t('general.no') }}
            </span>
          </span>
        </div>
        <div
          v-else-if="c.key === 'comment'"
          :key="c.key"
          :class="c.key"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
          class="px-1 comment text-truncate"
        >
          {{ order.comment || '-' }}
        </div>
        <div
          v-else-if="c.key === 'delivered'"
          :key="c.key"
          :class="c.key"
          class="px-1"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
        >
          <span
            v-if="order.delivered"
            class="text-success small text-uppercase"
          >
            <span
              class="fa-stack fa-1x"
              style="width: 18px"
            >
              <i class="far fa-hand-holding-box fa-stack-1x" />
            </span>

            {{ $t('general.yes') }}
          </span>
          <span
            v-else
            class="text-danger small text-uppercase"
          >
            <span
              class="fa-stack fa-1x"
              style="width: 18px"
            >
              <i class="far fa-hand-holding-box fa-stack-1x" />
              <i class="far fa-slash fa-stack-1x" />
            </span>
            <span>
              {{ $t('general.no') }}
            </span>
          </span>
        </div>
        <div
          v-else-if="c.key === 'space'"
          :key="c.key"
          class="flex-grow-1 align-self-center"
        >
          &nbsp;
        </div>
        <div
          v-else-if="c.key === 'count'"
          :key="c.key"
          :class="c.key"
          class="money-font text-right px-2"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
        >
          <span>{{ order.count }}</span>
        </div>
        <div
          v-else-if="c.key === 'discount'"
          :key="c.key"
          :class="c.key"
          class="text-right px-1"
          :style="c.width ? { minWidth: `${c.width}px` } : {}"
        >
          <span>{{ order.discountName }}</span>
        </div>
        <div
          v-else-if="c.key === 'price'"
          :key="c.key"
          :class="c.key"
          class="money-font column-divider text-right d-flex align-items-center justify-content-end pr-2"
        >
          <span v-if="order.discountName">
            <Tippy>

              <template #trigger>
                <i class="fas fa-badge-percent text-primary pr-2" />
              </template>

              {{ order.discountName }}
              <div class="text-strikethrough">
                {{ formatCurrency(order.priceBeforeDiscount, order.currency) }}
              </div>

            </Tippy>
          </span>

          {{ formatCurrency(order.unitPrice, order.currency) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import ordersColumns from '@/pages/shop/order/columns';
import colors from '@/utils/colors';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    checked: Boolean,
    selected: Boolean,
    columns: Array,
    groupBy: String,
    order: Object,
    payer: Object,
    last: Boolean,
    noCheckbox: Boolean,
  },
  data: () => ({
    ordersColumns,
  }),
  components: {
    PayerName,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    ...mapGetters('shop', [
      'singleOrderSchema',
      'catalogDetails',
    ]),
    columnsWithNames() {
      return this.columns.map((c) => {
        const orderColumn = this.ordersColumns.find((x) => x.key === c.key);
        if (!orderColumn) return c;
        return {
          ...c,
          ...orderColumn,
        };
      });
    },
  },
  methods: {
    colors,
    formatDate(date) {
      return moment(date).format('DD MMM YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .date {
    font-size: 12px;
  }

  .status-name {
    .badge {
      font-size: 10px;
    }
  }

  .set-row-hover:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }

  .offer-name {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
  }

  .aggregation {
    font-size: 13px;
    line-height: 1.2;
  }

  .aggregatedPrice,
  .aggregatedCount {
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #fafafa;
  }

  .aggregatedCount {
    border-left: 1px solid #ddd;
    padding-left: 10px !important;
  }

  .aggregatedPrice {
    border-right: 1px solid #ddd;
    padding-right: 10px !important;
    margin-right: 10px !important;
  }

  .set-row {
    border-top: 1px solid #ddd;
    padding: 2px 0px;
    cursor: pointer;
    height: 32px;
    align-items: center;

    &.last {
      border-bottom: 1px solid #ddd;
    }

    &.no-padding {
      padding: 0px;
    }

    &.checkbox {
      border-right: 1px solid rgba(100, 100, 100, 0.2);
      display: flex;
      justify-content: center;
    }

    &.selected {
      background-color: rgba($blue, 0.2);
    }
  }

  .comment {
    font-size: 12.8px;
  }

  .check {
    font-size: 1rem;
  }

  .uncheck {
    color: #f6f6f6
  }

  .payer-icon {
    color: $light-gray;
  }
</style>
