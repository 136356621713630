<template>
  <div class="table-wrapper">
    <div class="d-flex">
      <div class="payer-name">
        <label>
          {{ $t('menu.payers') }}
        </label>
      </div>
      <div
        v-for="d in selectedDays"
        :key="d"
        class="day-box"
      >
        <label>
          {{ formatDay(d) }}
        </label>
      </div>
    </div>
    <div
      v-for="p in payers"
      :key="p.id"
      class="d-flex align-items-center"
    >
      <div class="payer-name">
        <PayerName v-bind="p" />
      </div>
      <div
        v-for="d in getPayerDays(p.id)"
        :key="d.day"
        class="day-box"
      >
        <span
          v-if="d.listingIds.length > 0"
          class="text-primary small font-weight-bold"
        >
          +{{ d.listingIds.length }}
        </span>

        <Tippy
          v-if="d?.error"
        >
          <template #trigger>
            <span class="text-danger">
              <i class="fas fa-circle-exclamation" />
            </span>
          </template>
          <div style="white-space: pre-wrap">
            {{ $t(`shop.orderError.${d.error}`) }}
            <span
              v-if="d.unavailableListings"
              class="font-weight-bold"
            >
              {{ d.unavailableListings }}
            </span>
          </div>
        </Tippy>
      </div>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    payers: Array,
    selectedDays: Array,
    calendar: Array,
    offer: Array,
  },
  components: {
    PayerName,
  },
  computed: {
    ...mapGetters('shop', ['listings']),
    dayStatus() {
      return this.selectedDays.reduce((acc, d) => {
        const dayListings = this.calendar
          .filter((x) => x.day === d)
          .map((x) => x.listingId);

        this.offer.forEach(({ payerId, listingIds }) => {
          const unavailableListings = listingIds
            .filter((x) => !dayListings.includes(x));

          if (dayListings.length === 0) {
            acc[`${d}-${payerId}`] = {
              day: d,
              payerId,
              error: 'noPlan',
            };
          } else if (unavailableListings.length > 0) {
            acc[`${d}-${payerId}`] = {
              day: d,
              payerId,
              error: 'noMenu',
              listingIds: listingIds.filter((x) => dayListings.includes(x)),
              unavailableListings: unavailableListings
                .map((x) => this.listings.find((l) => l.id === x).name)
                .join('\n'),
            };
          } else {
            acc[`${d}-${payerId}`] = {
              day: d,
              payerId,
              listingIds: listingIds.filter((x) => dayListings.includes(x)),
            };
          }
        });

        return acc;
      }, {});
    },
  },
  watch: {
    dayStatus() {
      this.emit();
    },
  },
  methods: {
    emit() {
      const orders = Object.values(this.dayStatus)
        .flatMap((x) => x.listingIds.map((id) => ({
          listingId: id,
          payerId: x.payerId,
          day: x.day,
          count: 1,
          comment: '',
        })));

      this.$emit('orders', orders);
    },
    getPayerDays(pid) {
      return this.selectedDays
        .map((d) => this.dayStatus[`${d}-${pid}`]);
    },
    formatDay(d) {
      return moment(d).format('DD MMM');
    },
  },
  created() {
    this.emit();
  },
};
</script>

<style lang="scss" scoped>
$border-color: #efefef;

.table-wrapper {
  overflow-x: auto;
}

.payer-name {
  width: 300px;
  min-width: 300px;
  border-bottom: 1px solid #efefef;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  padding: 2px 10px;
}

.day-box {
  width: 75px;
  min-width: 75px;
  border-bottom: 1px solid $border-color;
  border-right: 1px solid $border-color;
  padding: 2px 10px;
}
</style>
