import lang from '@/lang';

export const commonColumns = [
  {
    key: 'name',
    type: 'default',
    name: lang.t('client.name'),
    width: 250,
  },
  {
    key: 'code',
    type: 'default',
    name: lang.t('client.clientCode'),
    width: 150,
  },
];

export const extraColumns = [
  {
    key: 'productSetName',
    type: 'other',
    name: lang.t('shop.offer.offer'),
    icon: 'far fa-box',
    width: 160,
  },
  {
    key: 'day',
    type: 'other',
    name: lang.t('time.day'),
    icon: 'far fa-calendar-day',
    width: 60,
  },
  {
    key: 'created',
    type: 'other',
    name: lang.t('shop.created'),
    icon: 'far fa-clock',
    width: 120,
  },
  {
    key: 'status',
    type: 'other',
    name: lang.t('shop.orderStatus'),
    icon: 'far fa-box-circle-check',
    width: 140,
  },
  {
    key: 'paid',
    type: 'other',
    name: lang.t('shop.isPaid'),
    icon: 'far fa-circle-dollar',
    width: 100,
  },
  {
    key: 'delivered',
    type: 'other',
    name: lang.t('shop.delivered'),
    icon: 'far fa-hand-holding-box',
  },
  {
    key: 'count',
    type: 'other',
    name: lang.t('shop.listing.count'),
    icon: 'far fa-boxes-stacked',
  },
  {
    key: 'price',
    type: 'other',
    name: lang.t('shop.listing.price'),
    icon: 'far fa-money-bill-wave',
  },
  {
    key: 'discount',
    type: 'other',
    name: lang.t('shop.discount'),
    icon: 'far fa-percent',
  },
  {
    key: 'comment',
    type: 'other',
    name: lang.t('shop.comment'),
    icon: 'far fa-comment',
  },
];

export default [
  ...commonColumns,
  ...extraColumns,
];
