<template>
  <span v-if="formatType === 'checkbox'">
    <i
      v-if="!!value && value === '1'"
      class="fas fa-square-check text-primary check"
    />
    <i
      v-else
      class="fa-solid fa-square check uncheck"
    />

  </span>
  <span v-else>
    {{ value }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    code: String,
    value: String,
  },
  computed: {
    ...mapGetters([
      'properties',
    ]),
    property() {
      return this.properties.find((x) => x.code === this.code);
    },
    formatType() {
      return (this.property?.format || '').split(':')[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.check {
  font-size: 1rem;
}

.uncheck {
  color: #f6f6f6
}
</style>
