<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
    :max-width="500"
    @shown="refresh()"
  >
    <template #trigger>
      <div class="sort-option-selected position-relative">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ title || $t('time.period') }}
        </div>
        <div
          style="font-size: 14px"
        >
          {{ selectTitle }}
        </div>

        <i
          v-if="allowNone && highlighted.length > 0"
          class="far fa-close close-btn"
          @click.stop="clear"
        />
      </div>
    </template>

    <div
      style="width: 350px; margin: 10px 5px"
      class="position-relative"
    >
      <Calendar
        ref="calendar"
        :available-days="availableDays"
        :selected-days="[]"
        :init-at="initAt"
        :day-status="dayStatus"
        :single-day="singleDay"
        :all-available="allAvailable"
        compressed
        @dates="$emit('update:dates', $event)"
        @highlighted="$emit('update:highlighted', $event)"
      />
    </div>
  </Tippy>
</template>

<script>
import Calendar from '@/modules/payer/components/shop/Calendar';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    highlighted: {
      type: Array,
      default: () => [],
    },
    availableDays: {
      type: Array,
      default: () => [],
    },
    dayStatus: {
      type: Object,
      default: () => {},
    },
    initAt: String,
    title: String,
    dates: Object,
    singleDay: Boolean,
    allAvailable: Boolean,
    allowNone: Boolean,
  },
  data: () => ({
    show: false,
  }),
  components: {
    Calendar,
  },
  computed: {
    ...mapGetters(['payee']),
    selectTitle() {
      if (!this.dates) return '';
      if (this.highlighted.length === 0) {
        if (this.allowNone) {
          return '-';
        }

        if (this.singleDay) {
          return moment().format('DD MMMM');
        }
        return moment(this.dates.from).format('MMMM YYYY');
      }
      if (this.highlighted.length === 1) {
        return moment(this.highlighted[0], 'YYYY-MM-DD').format('DD MMMM');
      }

      const first = this.highlighted[0];
      const last = this.highlighted[this.highlighted.length - 1];

      return `${moment(first, 'YYYY-MM-DD').format('DD MMMM')} - ${moment(last, 'YYYY-MM-DD').format('DD MMMM')}`;
    },
    firstYear() {
      if (!this.payee) return this.selected;
      return moment.utc(this.payee.oldestBillingScope).year();
    },
    years() {
      let current = parseInt(moment().format('YYYY'), 10);
      const years = [];

      while (current >= this.firstYear) {
        years.push(current);
        current -= 1;
      }

      return years;
    },
  },
  watch: {
    selectTitle(title) {
      this.$emit('title', title);
    },
  },
  methods: {
    refresh() {
      this.$refs.calendar.calculateWrapper();
    },
    clear() {
      this.$refs.calendar.clear();
    },
  },
  created() {
    this.$emit('title', this.selectTitle);
  },
};
</script>

<style lang="scss" scoped>
 @import '@/components/payers/style.scss';

 .close-btn {
    position: absolute;
    cursor: pointer;
    font-size: 20px;
    width: 24px;
    right: 10px;
    top: 11px;
    background-color: rgba(#ddd, 0.4);
    padding: 2px;
    text-align: center;
    border-radius: 50%;
    color: #888;

    &:hover {
      color: #333;
      background-color: #ddd;
    }
  }
</style>
