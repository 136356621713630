<template>
  <div>
    <hr class="mt-1 mb-2">
    <div
      class="d-flex align-items-center"
      style="height: 28px"
    >
      <div
        class="mr-2 checkbox align-self-center"
        @click="checkAll(orders)"
      >
        <Checkbox
          :value="allChecked(orders)"
          :emit="false"
          style="position: relative; margin-left: -5px"
          class="px-0"
        />
      </div>

      <div
        v-if="checkedOrders.length > 0"
        class="flex-grow-1 d-flex align-items-center"
      >
        <Tippy
          ref="archive-popup"
          trigger="click"
          theme="light"
          arrow
          interactive
        >
          <template #trigger>
            <button
              v-tippy
              :content="$t('shop.cancel')"
              class="btn btn-sm btn-outline-primary icon-btn mx-1"
            >
              <i class="fas fa-ban" />
            </button>
          </template>

          <button
            class="btn btn-primary btn-sm btn-block"
            :disabled="pendingCancel"
            @click="cancelCheckedOrders"
          >
            <i class="fas fa-ban pr-2" />
            {{ $t('shop.cancel') }}
          </button>
        </Tippy>

        <button
          v-tippy
          :content="$t('general.edit')"
          class="btn btn-sm btn-outline-primary icon-btn mx-1"
          @click="$emit('bulkEdit', checkedOrders)"
        >
          <i class="fas fa-pen" />
        </button>

        <div class="pl-2 small text-primary">
          {{ $t('shop.orders') }}: {{ checkedCount }}
        </div>
      </div>
      <div
        v-else
        class="flex-grow-1"
      />
      <template v-if="!editedId">
        <div class="money-font pr-2">
          {{ totalCount }}
        </div>
        <div
          class="money-font text-right column-divider pr-2"
          style="width: 150px"
        >
          {{ formatCurrency(totalPrice.price, totalPrice.currency) }}
        </div>
      </template>
    </div>
    <hr class="mb-0 mt-2">
    <div class="orders-list-body">
      <div
        class="header-wrapper"
      >
        <OrderHeaderRow
          v-if="groupBy != 'none'"
          :columns="groupLayout"
          class="group-header"
          :class="{ 'list-padding': groupBy != 'none' }"
        />
      </div>
      <div
        v-for="(p, index) in aggregatedOrders"
        :key="p.id"
      >
        <div
          v-if="groupBy != 'none'"
          class="checkbox set-row-group d-flex align-items-center"
          :class="{ 'last': index === aggregatedOrders.length - 1 }"
        >
          <div
            class="select-box angle"
            @click="$set(opened, p.id, !opened[p.id])"
          >
            <i
              :class="opened[p.id] ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'"
            />
          </div>
          <div
            class="select-box"
            @click="checkAll(p.orders)"
          >
            <Checkbox
              :value="allChecked(p.orders)"
              :emit="false"
              class="px-0"
            />
          </div>
          <OrderItemRow
            :last="false"
            :columns="groupLayout"
            :order="p"
            :payer="p.payer"
            class="group-row"
            no-checkbox
          />
        </div>
        <div
          v-if="opened[p.id] || groupBy === 'none'"
          :class="groupBy != 'none' ? 'inner-table' : ''"
        >
          <OrderHeaderRow
            :columns="innerLayout"
            :sort-by.sync="sortBy"
            :class="groupBy == 'none' ? 'group-header' : 'inner-header'"
          />
          <OrderItemRow
            v-for="(o, i) in p.orders"
            :key="o.id"
            :columns="innerLayout"
            :order="o"
            :last="groupBy === 'none' && i === (p.orders.length - 1)"
            :payer="o.payer"
            :checked="isChecked(o)"
            :selected="editedId === o.id"
            @toggle="toggle(o, !isChecked(o))"
            @select="$emit('select', o)"
          />
        </div>
      </div>
      <div
        v-if="pending"
        class="mt-4"
      >
        <Loader />
      </div>

      <div
        v-else-if="aggregatedOrders.length === 0"
        class="text-center py-3 text-secondary"
      >
        <div style="font-size: 1rem">
          <span class="fa-stack fa-2x">
            <i class="far fa-box" />
            <i class="far fa-slash fa-stack-1x" />
          </span>
        </div>
        {{ $t('shop.noOrders') }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import OrderHeaderRow from './OrderHeaderRow';
import OrderItemRow from './OrderItemRow';

export default {
  props: {
    orders: Array,
    payers: Array,
    productSets: Array,
    layout: Array,
    dates: Object,
    editedId: String,
    groupBy: String,
    pending: Boolean,
  },
  data: () => ({
    pendingDownload: false,
    selectedProductSetId: null,
    section: 'orders',
    sortBy: 'payer',
    opened: {},
    checked: {},
    showBulkEdit: false,
    pendingCancel: false,
  }),
  components: {
    OrderItemRow,
    OrderHeaderRow,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    ...mapGetters('shop', ['singleOrderSchema', 'catalogDetails']),
    checkedOrders() {
      return this.orders.filter((o) => this.isChecked(o));
    },
    innerLayout() {
      if (this.groupBy === 'none') return this.layout;
      if (this.groupBy === 'payers') {
        return this.layout
          .filter((x) => x.key !== 'code'
            && x.key !== 'name'
            && x.type !== 'property'
            && x.type !== 'group');
      }
      if (this.groupBy === 'productSets') {
        return this.layout
          .filter((x) => x.key !== 'productSetName');
      }
      if (this.groupBy === 'day') {
        return this.layout
          .filter((x) => x.key !== 'day');
      }

      return this.layout;
    },
    groupLayout() {
      if (this.groupBy === 'none') return this.layout;
      const groupLayout = this.layout.filter((x) => !this.innerLayout.some((l) => l.key === x.key));

      return [
        ...(groupLayout.length > 0 ? [groupLayout[0]] : []),
        { key: 'aggregatedCount', width: 60, name: this.$t('shop.offer.aggregatedCount') },
        { key: 'aggregatedPrice', width: 110, name: this.$t('shop.offer.aggregatedPrice') },
        // ...groupLayout,
        ...(groupLayout.length > 1 ? groupLayout.slice(1) : []),
      ];
    },
    checkedCount() {
      return this.checkedOrders.reduce((acc, curr) => acc + curr.count, 0);
    },
    totalPrice() {
      return this.orders.reduce((acc, curr) => {
        acc.currency = curr.currency;
        acc.price += (curr.unitPrice * curr.count);
        return acc;
      }, { price: 0, currency: '' });
    },
    totalCount() {
      return this.orders.reduce((acc, curr) => acc + curr.count, 0);
    },
    selected() {
      if (!this.selectedProductSetId) return null;
      return this.ordersAggregated[this.selectedProductSetId];
    },
    payersDictionary() {
      return this.payers.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
    productSetsDictionary() {
      return this.productSets.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
    statusDictionary() {
      return this.catalogDetails.orderStatuses.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
    ordersWithData() {
      return this.orders
        .map((o) => ({
          ...o,
          id: `${o.orderId}_${o.payerId}_${o.availabilityId}_${o.day}`,
          payer: this.payersDictionary[o.payerId],
          status: this.statusDictionary[o.orderStatusId],
          set: this.productSetsDictionary[o.productSetId] || {},
          formattedDay: moment(o.day, 'YYYY-MM-DD').format('DD/MM'),
        }))
        .sort((a, b) => {
          if (this.sortBy === 'created-up') {
            return moment(b.created).unix() - moment(a.created).unix();
          }
          if (this.sortBy === 'created-down') {
            return moment(b.created).unix() - moment(a.created).unix();
          }
          if (this.sortBy === 'day-up') {
            return moment(b.day).unix() - moment(a.day).unix();
          }
          if (this.sortBy === 'day-down') {
            return moment(b.day).unix() - moment(a.day).unix();
          }
          return a.payer.name.localeCompare(b.payer.name);
        });
    },
    aggregatedOrders() {
      if (this.groupBy === 'payers') {
        return this.ordersByPayers;
      }

      if (this.groupBy === 'productSets') {
        return this.ordersBySets;
      }

      if (this.groupBy === 'day') {
        return this.ordersByDay;
      }

      return this.ordersByNone;
    },
    ordersByNone() {
      return Object.values(this.ordersWithData
        .reduce((acc, curr) => {
          const key = 'single';

          if (!acc[key]) {
            acc[key] = {
              id: key,
              payerId: curr.payerId,
              orders: [],
              totalPrice: 0,
              totalCount: 0,
              currency: curr.currency,
            };
          }

          acc[key].totalPrice += (curr.unitPrice * curr.count);
          acc[key].totalCount += curr.count;
          acc[key].orders.push(curr);

          return acc;
        }, {}))
        .map((x) => ({
          ...x,
          orders: x.orders.sort((a, b) => moment(b.created).unix() - moment(a.created).unix()),
        }));
    },
    ordersByPayers() {
      return Object.values(this.ordersWithData
        .reduce((acc, curr) => {
          if (!acc[curr.payerId]) {
            acc[curr.payerId] = {
              id: curr.payerId,
              payerId: curr.payerId,
              payer: curr.payer,
              orders: [],
              totalPrice: 0,
              totalCount: 0,
              currency: curr.currency,
            };
          }

          acc[curr.payerId].totalPrice += (curr.unitPrice * curr.count);
          acc[curr.payerId].totalCount += curr.count;
          acc[curr.payerId].orders.push(curr);

          return acc;
        }, {}))
        .sort((a, b) => a.payer.name.localeCompare(b.payer.name));
    },
    ordersByDay() {
      return Object.values(this.ordersWithData
        .reduce((acc, curr) => {
          if (!acc[curr.day]) {
            acc[curr.day] = {
              id: curr.day,
              formattedDay: curr.formattedDay,
              day: curr.day,
              orders: [],
              totalPrice: 0,
              totalCount: 0,
              currency: curr.currency,
            };
          }

          acc[curr.day].totalPrice += (curr.unitPrice * curr.count);
          acc[curr.day].totalCount += curr.count;
          acc[curr.day].orders.push(curr);

          return acc;
        }, {}))
        .sort((a, b) => moment(a.day).unix() - moment(b.day).unix());
    },
    ordersBySets() {
      return Object.values(this.ordersWithData
        .reduce((acc, curr) => {
          if (!acc[curr.productSetId]) {
            acc[curr.productSetId] = {
              id: curr.productSetId,
              set: curr.set,
              orders: [],
              totalPrice: 0,
              currency: curr.currency,
              totalCount: 0,
            };
          }

          acc[curr.productSetId].totalPrice += (curr.unitPrice * curr.count);
          acc[curr.productSetId].totalCount += curr.count;

          acc[curr.productSetId].orders.push(curr);
          return acc;
        }, {}))
        .sort((a, b) => (a.set.name || '').localeCompare(b.set.name || ''));
    },
  },
  methods: {
    ...mapActions('shop', [
      'updateOrders',
    ]),
    isChecked(o) {
      return this.checked[`${o.payerId}_${o.day}_${o.availabilityId}_${o.isPaid}_${o.orderId}`];
    },
    toggle(o, value) {
      this.$set(this.checked, `${o.payerId}_${o.day}_${o.availabilityId}_${o.isPaid}_${o.orderId}`, value);
    },
    formatDate(date) {
      return moment(date).format('HH:mm DD/MM/YYYY');
    },
    cancelCheckedOrders() {
      this.pendingCancel = true;
      this.updateOrders({
        data: {
          catalogId: this.catalogDetails.id,
          cancelledItems: this.checkedOrders.map((x) => ({
            day: x.day,
            payerId: x.payerId,
            availabilityId: x.availabilityId,
            count: 1,
          })),
        },
      })
        .then(() => {
          this.$emit('refresh');
        })
        .finally(() => {
          this.pendingCancel = false;
        });
    },
    productSetName(productSetId) {
      return this.productSets.find((x) => x.id === productSetId)?.name || '?';
    },
    allChecked(payerOrders) {
      return payerOrders.every((o) => this.isChecked(o));
    },
    checkAll(payerOrders) {
      const deselect = this.allChecked(payerOrders);

      payerOrders.forEach((o) => {
        this.toggle(o, !deselect);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.select-box {
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(100, 100, 100, 0.2);
  cursor: pointer;

  &.angle {
    color: #aaa;
  }

  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }
}

.list-padding {
  padding-left: 64px;
}

.set-row-group {
  height: 32px;
  border-top: 1px solid #ddd;
  width: min-content;
  min-width: 100%;

  &.last {
    border-bottom: 1px solid #ddd;
  }
}

.text-strikethrough {
  text-decoration: line-through;
}

.orders-list-body {
  height: calc(100vh - 360px);
  overflow: auto;
  padding-bottom: 20px;
}

.payer-row-content {
  padding: 5px 10px 5px 5px;
}

.column-divider {
  border-left: 1px solid rgba(100, 100, 100, 0.1);
}

.money-font {
  font-size: 0.9rem;
}

.inner-table {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 27px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  width: min-content;
}

.inner-header {
  background-color: #f6f6f6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.group-header {
  background-color: #f6f6f6;
  width: min-content;
  min-width: 100%;
}

.collapse-icon {
  width: 27px;
  text-align: center;
  margin-left: 5px;
  line-height: 30px;
  display: flex;
  align-items: center;

  i {
    background-color: #f6f6f6;
    padding: 5px;
    border-radius: 50%;
    width: 22px;
    min-width: 22px;
    height: 22px;
    line-height: 12px;
    font-size: 15px;
    color: #666;
  }
}

.main-column {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  font-weight: 0.9rem;
}

.header-wrapper {
  min-width: min-content;
}

.group-row {
  &:deep(.set-row) {
    border-top: none;
    cursor: default;
  }
  &:deep(.set-row-hover:hover) {
    background-color: initial;
  }
}

.orders-count {
  width: 120px;
  font-weight: 400;
  border-radius: 5px;
  text-align: right;
}

.orders-paid {
  color: $blue;
  text-align: right;
  width: 120px;
}

.orders-unpaid {
  color: $orange;
  text-align: right;
  width: 120px;
}
</style>
