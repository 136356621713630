<template>
  <div>
    <div
      class="input-group position-relative"
      :class="{
        highlighted,
        'input-group-sm': size === 'sm'
      }"
    >
      <input
        ref="money"
        v-model.trim="amount"
        type="text"
        class="form-control money-font"
        :class="alignLeft ? 'text-left' : 'text-right'"
        :style="{ 'color': parseInt(integerAmount, 10) === 0 ? '#bbb' : null }"
        data-test="price-input"
        :placeholder="label || '0.00'"
        @blur="blur(true)"
        @click="$refs.money.select();"
        @focus="focus"
        @keyup.down="emitDown"
        @keyup.up="emitUp"
        @keyup.left="emitLeft"
        @keyup.right="emitRight"
      >
      <div
        v-if="error"
        class="text-danger position-absolute bg-white small"
        style="top: -5px; right: 5px; font-size: 13px; line-height: 1.1; z-index: 4"
      >
        <i class="ion ion-ios-alert pr-1" />{{ error }}
      </div>
      <div
        v-if="selectCurrency && currencies.length > 1"
        class="input-group-append"
      >
        <BDropdown
          id="dropdown-1"
          :text="currency ? currency.toUpperCase() : $t('settings.currencies.currency')"
          class="m-m-2 size"
          size="sm"
        >
          <div
            v-for="curr in currencies"
            :key="curr.code"
          >
            <BDropdownItem
              @click="updateCurrency(curr)"
            >
              <div class="text-uppercase">
                {{ curr.code }}
              </div>
            </BDropdownItem>
          </div>
        </Bdropdown>
      </div>
      <div
        v-else-if="currency"
        class="input-group-append"
      >
        <span class="input-group-text currency text-uppercase">{{ currency }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { money } from '@/utils/filters';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: Number,
    size: String,
    label: String,
    alignLeft: Boolean,
    onlyPositive: {
      type: Boolean,
      default: true,
    },
    highlighted: Boolean,
    selectCurrency: Boolean,
    currency: String,
    autoSelect: Boolean,
  },
  data: () => ({
    innerAmount: '',
    error: null,
    focused: false,
  }),
  computed: {
    ...mapGetters(['currencies']),
    currencyDefinition() {
      if (!this.currency) return {};
      return this.currencies.find((x) => x.code.toLowerCase() === this.currency.toLowerCase());
    },
    amount: {
      get() {
        return this.innerAmount;
      },
      set(v) {
        if (v) {
          this.innerAmount = v
            .replace(/\s/g, '')
            .replace('.', ',')
            .replace(/[^\-0-9,]/g, '');
        }
        if (this.onlyPositive) {
          this.innerAmount = this.innerAmount.replace(/-/g, '');
        }
        this.emit();
      },
    },
    integerAmount() {
      const amount = this.innerAmount.replace(/[^\-0-9,]/g, '');
      const parts = amount.split(',');
      const decimals = this.currencyDefinition?.decimals ?? 0;
      const missingDecimal = ''.padStart(decimals, '0');
      if (parts.length < 2 || decimals <= 0) {
        return `${amount.replace(',', '')}${missingDecimal}`;
      }

      const decimal = parts[1].padEnd(decimals, '0');
      return `${parts[0]}${decimal}`;
    },
  },
  watch: {
    value(v) {
      if (!this.focused) {
        this.innerAmount = money((v || '').toString(), this.currencyDefinition?.decimals || 0);
        this.blur(false);
      }
    },
    currency() {
      this.blur(false);
    },
  },
  methods: {
    updateCurrency(curr) {
      this.$emit('update:currency', curr.code);
      this.$nextTick(() => {
        this.blur(false);
      });
    },
    select() {
      if (this.$refs.money) {
        this.$refs.money.select();
        this.$refs.money.focus();
      }
    },
    focus() {
      this.focused = true;
      this.innerAmount = this.innerAmount.replace(/\s/g, '');
      this.$emit('focused');
    },
    blur(emit) {
      this.innerAmount = money(this.integerAmount, this.currencyDefinition?.decimals || 0);
      this.focused = false;
      if (emit) {
        this.$emit('blur');
      }
    },
    emit() {
      this.error = null;
      if (this.innerAmount === '-') return;

      const value = parseInt(this.integerAmount, 10);
      if (Number.isNaN(value)) {
        this.error = this.$t('error.invalidAmount');
        this.$emit('input', null);
      } else {
        this.$emit('input', value);
      }
    },
    emitDown() {
      this.$emit('changeFocus', 38);
    },
    emitUp() {
      this.$emit('changeFocus', 40);
    },
    emitLeft() {
      this.$emit('changeFocus', 37);
    },
    emitRight() {
      this.$emit('changeFocus', 39);
    },
  },
  mounted() {
    if (this.autoSelect) {
      this.select();
    }
  },
  created() {
    this.innerAmount = money((this.value || '').toString(), this.currencyDefinition?.decimals || 0);
    this.blur(false);
  },
};
</script>

<style lang="scss" scoped>
  .font-small {
    font-size: 60%;
    margin-right: 5%;
  }

  .highlighted {
    input {
      border-color: $blue;
    }
    .currency {
      border-color: $blue;
      background-color: rgba($blue, 0.2);
    }
  }
</style>
