<template>
  <div>
    <div class="d-flex align-items-center py-3 px-2">
      <div class="bill-icon flex-shrink-0">
        <i class="icon-bill" />
      </div>
      <div class="title">
        {{ bill.title }}

        <span
          v-if="bill.code"
          v-tippy
          :content="$t('bill.code')"
          class="code pl-1"
        >
          <i class="fas fa-hashtag" />{{ bill.code }}
        </span>
        <div
          v-if="showCategories"
          class="category"
        >
          <CategoryInfo :category-id="bill.categoryId" />
        </div>
      </div>
    </div>
    <BillPayerDetails
      v-if="payerDetails"
      :bill-id="bill.id"
      :currency="bill.currency"
      :bill-title="bill.title"
      :color="color"
      :payer="payerDetails"
      @cancel="payerDetails = null"
    />
    <div v-show="!payerDetails">
      <hr class="mt-0">
      <BillControls
        v-if="details"
        :id="bill.id"
        :external-id="details.externalId"
        :block-delete="selectedPayerIds.length > 0"
        :bill-title="bill.title"
        :action.sync="action"
        @close="selected = null"
        @removed="removeBill"
        @refresh="$emit('refresh')"
      />
      <hr class="mt-2">
      <div v-if="!action">
        <div style="height: 200px">
          <BillDetails
            v-if="details"
            :bill="details"
          />
          <div
            v-else
            class="empty-details"
          />
        </div>
        <BillPayersList
          class="pb-3"
          :bill-id="bill.id"
          :color="color"
          :selected-payer-ids="selectedPayerIds"
          :currency="bill.currency"
          @select="payerDetails = $event"
          @focus="$emit('focus', $event)"
        />
      </div>
      <div v-else-if="action ==='details'">
        <BillDetailsForm
          v-if="details"
          :color="color"
          :bill="details"
          @close="action = ''"
          @update="details = $event; action = ''; $emit('update', $event)"
        />
      </div>
      <div v-else>
        <BillPayersEditList
          v-if="details"
          :key="action"
          :selected-payer-ids="selectedPayerIds"
          :bill="details"
          :edit="action !== 'add'"
          @cancel="action = ''"
          @update="details = $event; action = ''; $emit('update', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import colors from '@/utils/colors';
import { mapActions, mapGetters } from 'vuex';
import BillPayerDetails from '../BillPayerDetails';
import BillControls from './BillControls';
import BillDetails from './BillDetails';
import BillDetailsForm from './BillDetailsForm';
import BillPayersEditList from './BillPayersEditList';
import BillPayersList from './BillPayersList';

export default {
  props: {
    bill: Object,
    focus: Boolean,
    selectedPayerIds: Array,
  },
  data: () => ({
    details: null,
    pending: false,
    payerDetails: null,
    action: '',
  }),
  components: {
    BillControls,
    BillDetails,
    BillDetailsForm,
    BillPayersList,
    BillPayersEditList,
    BillPayerDetails,
    CategoryInfo,
  },
  computed: {
    ...mapGetters(['payee', 'showCategories']),
    color() {
      if (!this.bill) return '';
      const category = this.payee?.categories.find((x) => x.id === this.bill.categoryId);
      return category ? colors(category.colorCode) : colors('blue');
    },
  },
  watch: {
    bill() {
      this.details = null;
      this.request();
    },
    action(v) {
      if (v) {
        this.$emit('focus', true);
      } else {
        this.$emit('focus', false);
      }
    },
  },
  methods: {
    ...mapActions(['getBill']),
    removeBill() {
      this.$emit('remove', this.bill.id);
      this.$emit('close');
      this.$emit('focus', false);
    },
    request() {
      this.pending = true;
      this.getBill({
        params: {
          billId: this.bill.id,
        },
      })
        .then(({ data }) => {
          this.details = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;

    .code {
      font-size: 12px;
      color: #aaa;
    }
  }

  .category {
    font-size: 12px;
    margin-top: 5px;
    color: #888;
  }

  .empty-details {
    height: 150px;
    background-color: #f8f9fa;
  }
</style>
