<template>
  <Tippy
    lazy
    theme="light"
  >
    <template #trigger>
      <a

        :href="`/payee/${payeeId}/shop/${catalogId}/offer?listingId=${listing.id}`"
        target="_blank"
        @click.stop
      >
        <slot />
      </a>
    </template>
    <div>
      <p v-if="listing.description">
        {{ listing.description }}
      </p>
      <hr
        v-if="listing.description"
        class="my-1"
      >

      <div
        v-for="s in listingSubCatalogs"
        :key="s.id"
        class="text-left"
      >
        {{ s.subCatalogName }}: <span class="font-weight-bold">{{ s.nodes }}</span>
      </div>
    </div>
    <small>
      {{ $t('shop.offer.clickToOpenListing') }}
    </small>
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    listing: Object,
  },
  computed: {
    ...mapGetters(['payeeId']),
    ...mapGetters('shop', [
      'catalogDetails',
    ]),
    catalogId() {
      return this.catalogDetails?.id;
    },
    listingSubCatalogs() {
      const nodeNames = this.catalogDetails?.subCatalogNodes.reduce((acc, curr) => {
        acc[curr.nodeId] = curr.name;
        return acc;
      }, {}) || {};

      return (this.catalogDetails?.subCatalogs || [])
        .map((c) => ({
          subCatalogName: this.catalogDetails?.subCatalogs
            .find((x) => x.subCatalogId === c.subCatalogId)?.name,
          nodes: this.listing.assignments
            .filter((a) => a.subCatalogId === c.subCatalogId)
            .map((a) => nodeNames[a.nodeId])
            .join(', '),
        }))
        .filter((x) => x.nodes.length > 0);
    },
  },
};
</script>
