<template>
  <div>
    <img
      v-show="!pending && !error"
      ref="preview"
      :src="filePreviewUrl"
      class="preview"
    >
    <div v-if="pending">
      <Loader />
    </div>
    <div
      v-else-if="error"
      class="text-warning text-center"
    >
      <div style="font-size: 25px">
        <i class="fa-solid fa-triangle-exclamation" />
      </div>
      {{ $t('accounting.previewNotAvailable') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filePreviewUrl: String,
  },
  data: () => ({
    pending: false,
    error: false,
  }),
};
</script>

<style lang="scss" scoped>
.preview {
  width: 100%;
  box-shadow: 0 0px 5px #f1f1f1;
}
</style>
