import { render, staticRenderFns } from "./SubCatalogEditor.vue?vue&type=template&id=d613cd64&scoped=true&"
import script from "./SubCatalogEditor.vue?vue&type=script&lang=js&"
export * from "./SubCatalogEditor.vue?vue&type=script&lang=js&"
import style0 from "./SubCatalogEditor.vue?vue&type=style&index=0&id=d613cd64&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d613cd64",
  null
  
)

export default component.exports