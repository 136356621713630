<template>
  <div>
    <div
      v-if="!paymentId"
      class="card-title"
    >
      {{ $t('payment.audit.audit') }}
      <span class="scope-title">
        - {{ billingScopeName(scope) }}
      </span>
    </div>
    <div v-if="pending">
      <Loader />
    </div>
    <div
      v-else-if="error"
      class="text-danger text-center pt-4"
    >
      <i
        class="fas fa-xmark"
        style="font-size: 25px"
      />
      <div>
        {{ $t('error.fail') }}
      </div>
    </div>
    <div v-else>
      <div class="change-log">
        <div
          v-for="op in list"
          :key="`${op.paymentId}_${op.timestamp}`"
        >
          <PaymentsAuditRow
            v-bind="op"
            :payers="payers"
            :bills="bills"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import PaymentsAuditRow from './PaymentsAuditRow';

export default {
  props: {
    scope: Object,
    paymentId: String,
    paymentDate: String,
  },
  data: () => ({
    list: [],
    payers: [],
    bills: [],
    error: false,
    pending: false,
    auditRange: {},
    users: {},
  }),
  components: {
    PaymentsAuditRow,
  },
  computed: {
    ...mapGetters(['locale', 'billingScopeName']),
  },
  watch: {
    scope() {
      this.request();
    },
    auditRange() {
      this.request();
    },
    paymentId() {
      this.payers = [];
      this.list = [];
      this.users = {};
      this.request();
    },
  },
  methods: {
    ...mapActions(['getPaymentsAudit']),
    request() {
      if (!this.scope && !this.paymentId) return;

      this.getPaymentsAudit({
        params: {
          query: {
            auditFrom: moment(this.auditRange.start).startOf('year').utc().format(),
            auditTo: moment().endOf('day').utc().format(),
            paymentsFrom: this.paymentId
              ? this.paymentDate
              : this.scope.from,
            paymentsTo: this.paymentId
              ? moment(this.paymentDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
              : this.scope.to,
            paymentId: this.paymentId,
          },
        },
      })
        .then(({ data }) => {
          this.payers = data.payers;
          data.users.forEach((u) => {
            this.$set(this.users, u.id, u);
          });
          this.list = data.audit
            .map((x) => ({
              ...x,
              user: this.users[x.requesterId],
            }));
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/components/audit.scss";

.card-title {
  font-weight: 600;
  font-size: 1.3rem;
}

.scope-title {
  font-weight: 600;
  text-transform: uppercase;
  color: $blue;
  font-size: 0.9rem;
}

.payer-section {
  font-size: 16px;
  font-weight: 600 !important;
}

</style>
