<template>
  <div v-if="!catalog && pending">
    <Loader />
  </div>
  <div
    v-else-if="!catalog"
    class="text-center font-weight-bold text-secondary"
  >
    Catalog does not exist!
  </div>
  <div
    v-else
    class="d-flex flex-column"
  >
    <div class="flex-grow-1 d-flex">
      <div class="sidebar">
        <div
          v-if="catalog.orderSchema === 'Daily'"
          class="p-3"
        >
          <Calendar
            all-available
            :day-status="activeDayCount"
            compressed
            @dates="refreshCalendar"
            @highlighted="highlighted = $event"
          />
        </div>
        <SubCatalogsSidebar
          :catalog="catalog"
          :selected.sync="selectedNodes"
          @refresh="requestCatalog"
        />
      </div>
      <div
        class="flex-grow-1 h-100"
        style="border-left: 1px solid #efefef; min-width: 0;"
      >
        <div class="list-header m-2">
          <div>
            <SearchBox v-model="search" />
          </div>

          <div class="d-flex justify-content-end">
            <div
              v-tippy
              class="secondary-icon ml-3"
              :content="$t('shop.offer.export')"
              @click="exportOffer"
            >
              <i
                v-if="!exportPending"
                class="fas fa-download"
              />
              <Loader
                v-else
                color="#666"
                size="15px"
                style="position: relative; top: 2px"
              />
            </div>
            <div
              v-tippy
              class="secondary-icon ml-3"
              :content="$t('shop.offer.upload')"
              @click="showUpload = true"
            >
              <i class="fa-solid fa-upload" />
            </div>

            <button
              class="btn btn-primary btn-sm px-3 ml-3"
              @click="editedId = null; form = true"
            >
              <i class="fas fa-plus mr-2" />
              <span class="edit-details">{{ $t('shop.offer.add') }}</span>
            </button>
          </div>
        </div>
        <ColumnEdit
          :edit="form || !!editedId"
          class="flex-grow-1 h-100"
        >
          <div
            class="full-height"
            style="padding-bottom: 20px;"
          >
            <NodeSelectedRow
              v-for="n in selectedNodeList"
              :key="n.nodeId"
              :node="n.node"
              :sub-catalogs="catalog.subCatalogs"
              @remove="selectedNodes = selectedNodes.filter(x => x.nodeId !== n.nodeId)"
            />

            <ListingImport
              v-if="showUpload"
              :catalog-id="catalogId"
              @close="showUpload = false"
            />
            <ListingsList
              v-else
              :edited-id="editedId"
              :listings="filteredListings"
              :active-listings="activeListings"
              @toggle="toggle"
            />
          </div>

          <template #edit>
            <div class="position-relative">
              <CloseButton
                v-if="editedId || form"
                style="position: absolute; right: -4px; top: -4px; z-index: 100"
                @click.native.stop="form = false; editedId = null"
              />
              <div
                v-if="editedId || form"
                class="page-cart full-height"
              >
                <ProductListingDetails
                  :listing="edited"
                  @close="form = false; editedId = null"
                />
              </div>
            </div>
          </template>
        </ColumnEdit>
      </div>
    </div>
  </div>
</template>

<script>
import ColumnEdit from '@/components/layout/ColumnEdit';
import Calendar from '@/modules/payer/components/shop/Calendar';
import getFileName from '@/utils/file-name';
import { accountNumber } from '@/utils/filters';
import createSearch from '@/utils/search';
import { mapActions, mapGetters, mapState } from 'vuex';
import ListingImport from './listing/ListingImport';
import ListingsList from './listing/ListingsList';
import ProductListingDetails from './listing/ProductListingDetails';
import NodeSelectedRow from './NodeSelectedRow';
import SubCatalogsSidebar from './SubCatalogsSidebar';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    editedId: null,
    form: false,
    pending: false,
    selectedNodes: [],
    calendar: [],
    highlighted: [],
    editedSubCatalog: null,
    search: '',
    showUpload: false,
    exportPending: false,
    importFile: null,
  }),
  components: {
    ColumnEdit,
    ListingImport,
    ListingsList,
    NodeSelectedRow,
    ProductListingDetails,
    SubCatalogsSidebar,
    Calendar,
  },
  computed: {
    ...mapGetters([
      'payee',
      'payeeId',
      'groups',
    ]),
    ...mapGetters('shop', ['listings']),
    ...mapState({
      catalog: (state) => state.shop.catalogs.catalogDetails,
    }),
    edited() {
      return this.listings.find((x) => x.id === this.editedId);
    },
    activeDayCount() {
      return this.calendar
        .filter((x) => this.filteredListings.some((l) => l.id === x.listingId))
        .reduce((acc, x) => {
          if (!acc[x.day]) {
            acc[x.day] = { paid: 0 };
          }
          acc[x.day].paid += 1;
          return acc;
        }, {});
    },
    activeListings() {
      if (this.catalog.orderSchema === 'Daily') {
        return this.calendar
          .filter((x) => this.highlighted.length === 0
            || this.highlighted.some((y) => x.day === y))
          .map((x) => x.listingId);
      }
      return this.filteredListings.map((x) => x.id);
    },
    filteredListings() {
      const search = createSearch(this.search);

      const productIds = this.selectedNodes.filter((x) => x.subCatalogId === '#products').map((x) => x.nodeId);

      return this.listings
        .filter((x) => search(x.name))
        .filter((x) => productIds.length === 0 || productIds.every((id) => x.productIds.some((n) => n === id)))
        .filter((x) => this.selectedNodes
          .filter(({ subCatalogId }) => subCatalogId !== '#products')
          .every(({ nodeId }) => x.assignments.some((n) => n.nodeId === nodeId)));
    },
    selectedNodeList() {
      return this.selectedNodes
        .map(({ nodeId, subCatalogId }) => ({
          node: this.catalog.subCatalogNodes.find((n) => n.nodeId === nodeId)
          ?? this.groups.find((x) => x.code === nodeId),
          nodeId,
          subCatalogId,
        }))
        .filter((x) => x.node);
    },
    bankAccountName() {
      const name = this.payee.bankAccounts
        .find((x) => x.accountNumber.toString() === (this.catalog.accountNumber || '').toString())?.name;

      if (!name) {
        return accountNumber(this.catalog.accountNumber);
      }

      return name;
    },
  },
  methods: {
    ...mapActions('shop', [
      'getShopCatalog',
      'getCatalogListings',
      'getListingsCalendar',
      'offerExport',
    ]),
    exportOffer() {
      this.exportPending = true;
      this.offerExport({
        params: {
          catalogId: this.catalogId,
          query: {
            nodeIds: this.selectedNodes,
          },
        },
      })
        .then(({ data, headers }) => {
          const name = getFileName(headers);
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.exportPending = false;
        });
    },
    requestCatalog() {
      this.getShopCatalog({
        params: {
          catalogId: this.catalogId,
        },
      })
        .finally(() => {
          this.pending = false;
        });
    },
    refreshCalendar({ from, to }) {
      this.getListingsCalendar({
        params: {
          catalogId: this.catalogId,
          query: {
            from,
            to,
          },
        },
      })
        .then(({ data }) => {
          this.calendar = data;
        });
    },
    request() {
      this.getCatalogListings({
        params: {
          catalogId: this.catalogId,
        },
      });
    },
    toggle(listingId) {
      if (this.editedId === listingId) {
        this.editedId = null;
      } else {
        this.editedId = listingId;
      }
    },
  },
  created() {
    this.pending = true;
    this.$store.commit('shop/clearListings');
    this.request();
    this.requestCatalog();
    this.$emit('page', 'offer');
  },
  mounted() {
    this.editedId = this.$route.query.listingId;
  },
};
</script>

<style lang="scss" scoped>
.catalog-name {
  font-weight: 500;
  font-size: 17px;
  min-width: 400px;
}

.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(239, 239, 239)
}

.sidebar {
  height: calc(100vh - 200px);
  width: 320px;
  min-width: 320px;
  overflow-y: auto;
}

.catalog-type {
  color: #666;
  font-size: 0.7rem;
}

.category {
  font-size: 12px;
  color: #888;

  label {
    font-size: 0.7rem;
  }
}

.catalog-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
}

.full-height {
  height: calc(100vh - 270px);
  overflow: auto;
}
</style>
