<template>
  <div class="table-wrapper">
    <div class="d-flex">
      <div class="payer-name">
        <label>
          {{ $t('menu.payers') }}
        </label>
      </div>
      <div
        v-for="l in selectedListings"
        :key="l.id"
        class="day-box text-truncate"
      >
        {{ l.name }}
      </div>
    </div>
    <div
      v-for="p in payers"
      :key="p.id"
      class="d-flex align-items-center"
    >
      <div class="payer-name">
        <PayerName v-bind="p" />
      </div>
      <div
        v-for="l in selectedListings"
        :key="l.id"
        class="day-box text-truncate text-primary"
      >
        {{ isInOffer(l.id, p.id) ? '+1' : '' }}
      </div>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    payers: Array,
    offer: Array,
  },
  components: {
    PayerName,
  },
  computed: {
    ...mapGetters('shop', ['listings']),
    selectedListings() {
      const ids = this.offer
        .flatMap(({ listingIds }) => listingIds)
        .reduce((acc, curr) => {
          acc[curr] = 1;
          return acc;
        }, {});

      return this.listings.filter((x) => ids[x.id]);
    },
  },
  methods: {
    isInOffer(listingId, payerId) {
      return this.offer
        .some((x) => x.listingIds.includes(listingId) && x.payerId === payerId);
    },
    emit() {
      const orders = this.offer
        .flatMap((x) => x.listingIds.map((id) => ({
          listingId: id,
          payerId: x.payerId,
          day: '2000-01-01',
          count: 1,
          comment: '',
        })));

      this.$emit('orders', orders);
    },
    formatDay(d) {
      return moment(d).format('DD MMM');
    },
  },
  created() {
    this.emit();
  },
};
</script>

<style lang="scss" scoped>
$border-color: #efefef;

.table-wrapper {
  overflow-x: auto;
}

.payer-name {
  width: 300px;
  min-width: 300px;
  border-bottom: 1px solid #efefef;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  padding: 2px 10px;
}

.day-box {
  width: 130px;
  border-bottom: 1px solid $border-color;
  border-right: 1px solid $border-color;
  padding: 2px 10px;
}
</style>
