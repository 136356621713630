<template>
  <div
    v-if="pendingPayers"
    class="text-center mt-3"
  >
    <Loader />
  </div>
  <div v-else-if="userPayers.filter(x => !x.isInvite).length === 0">
    <div
      class="mt-4"
    >
      <div>
        <div
          class="font-weight-bold h5"
          v-html="$t('client.noConnectedClients')"
        />
        <div v-html="$t('client.noConnectedClientsShopInfo')" />
      </div>
    </div>
  </div>
  <div
    v-else-if="availableOffer.length === 0 && filteredCatalogs.length === 0"
    class="shop-baner"
  >
    <i class="far fa-basket-shopping" />
    <div v-html="$t('shop.thisIsShop')" />
  </div>
  <div
    v-else
    class="mt-4"
  >
    <div

      class="font-weight-bold"
    >
      {{ $t('shop.mealOffer') }}:
    </div>
    <div
      class="mt-4 payer-box-container"
    >
      <div
        v-for="p in availableOffer"
        :key="p.payerId"
        class="payer-box d-flex align-items-center"
        @click="select(p.payerId)"
      >
        <div class="user-icon-wrapper">
          <div class="user-icon text-center">
            <i
              class="far fa-fork-knife"
            />
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="payer-name">
            {{ p.payerName }}
          </div>
          <div class="payee-name">
            {{ p.payeeName }}
          </div>
        </div>
        <div class="orders-button pr-4">
          {{ $t('shop.order') }}
        </div>
      </div>
      <div
        v-for="catalog in filteredCatalogs"
        :key="catalog.id"
        class="payer-box d-flex align-items-center"
        @click="selectCatalog(catalog)"
      >
        <div class="user-icon-wrapper">
          <div class="user-icon text-center">
            <i
              class="far"
              :class="catalogIcon(catalog.productCategory)"
            />
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="payer-name">
            {{ catalog.name }}
          </div>
          <div class="payee-name">
            {{ payeeName(catalog.payeeId) }}
          </div>
        </div>
        <div class="orders-button pr-4">
          {{ $t('shop.order') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {

  data: () => ({
    offer: null,
    pendingPayers: false,
    summary: null,
  }),
  computed: {
    ...mapGetters([
      'userPayers',
      'userCatalogs',
      'formatCurrency',
      'catalogIcon',
    ]),
    ...mapState({
      basket: (state) => state.userBills.basket,
    }),
    toPay() {
      return Object.keys(this.basket)
        .filter((x) => x.startsWith('item'))
        .reduce((acc, key) => acc + this.basket[key].value, 0);
    },
    availableOffer() {
      if (this.pendingPayers) return [];
      return this.userPayers
        .filter((x) => x.shop && !x.isInvite)
        .filter((x) => (this.userCatalogs || [])
          .some((y) => x.payeeId === y.payeeId && y.id === '00000000-0000-0000-0000-000000000000'))
        .sort((a, b) => a.payerName.localeCompare(b.payerName));
    },
    filteredCatalogs() {
      return (this.userCatalogs || [])
        .filter((x) => x.id !== '00000000-0000-0000-0000-000000000000');
    },
  },
  methods: {
    ...mapActions([
      'getUserShop',
      'getUserPayers',
      'getUserCatalogs',
    ]),
    payeeName(payeeId) {
      return this.userPayers.find((x) => x.payeeId === payeeId)?.payeeName;
    },
    selectCatalog({ payeeId, id }) {
      this.$router.push(`/payer/shop/catalog/${payeeId}/${id}`);
    },
    select(payerId) {
      this.$router.push(`/payer/shop/${payerId}`);
    },
  },
  created() {
    this.$emit('page', 'shop');

    this.getUserCatalogs();

    if (!this.userPayers || this.userPayers.length === 0) {
      this.pendingPayers = true;
      this.getUserPayers()
        .finally(() => {
          this.pendingPayers = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
  .shop-baner {
    padding-top: 50px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    color: #aaa;

    i {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  .orders-button {
    text-align: center;
    font-weight: 400;
    color: #aaa;
    margin-top: 4px;
    padding: 5px 0;
  }

  .payer-box-container {
    padding-bottom: 20px;
    font-size: 14px;
    width: 100%;
  }

 .payer-box {
    width: 100%;
    max-width: 800px;
    height: 120px;
    margin-top: 20px;
    box-shadow: 0px 3px 10px rgb(221, 227, 235);
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: box-shadow 300ms, border 300ms;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid #aaa;

      .orders-button {
        color: $blue;
      }
    }

    &.invite {
      border: 2px solid $blue;
    }

    .payer-name {
      font-weight: 500;
    }

    .user-icon {
      color: #aaa;
      font-size: 20px;
      background-color: #D9E9FF;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $blue;
    }

    .user-icon-wrapper {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .payee-name {
      font-size: 12px;
    }

    .invite-accept {
      box-shadow: none;
      margin-top: 8px;
    }
 }
</style>
