<template>
  <div class="h-100 d-flex flex-column">
    <div class="d-flex mb-3 mt-1">
      <PayersGroupFilterDropdown
        :selected.sync="groupFilter"
        class="mr-3"
        hide-edit
      />
      <SearchBox
        v-model="search"
        lazy
        class="mr-3"
      />
    </div>
    <div class="d-flex flex-column h-100">
      <div>
        <div class="row no-gutters align-items-center">
          <div class="col-7 d-flex align-items-center">
            <Checkbox
              :value="allToggled"
              class="pr-1 align-items-center"
              @update:value="toggleAll($event)"
            >
              <div class="mb-0 label sm-label">
                {{ $t('general.selectAll') }}
              </div>
            </Checkbox>
          </div>
        </div>

        <hr class="mb-1 mt-2">
      </div>
      <div
        ref="payersList"
        style="overflow: auto; padding-bottom: 20px"
        :style="{ height: `calc(100vh - ${payersListHeight}px)` }"
      >
        <div
          v-for="payer in payersWithOffer"
          :key="payer.id"
          data-test="client-list-element"
          class="client-row w-100"
          @click="togglePayer(payer)"
        >
          <SimplePayerRow
            class="list-complete-item"
            v-bind="payer"
            :checked="payer.checked"
            :disabled="true"
            inline-code
          >
            <template #content>
              <template v-if="payer.checked">
                <Loader v-if="offerPending" />
                <div
                  v-else-if="payer.listings"
                  class="d-flex align-items-center"
                >
                  <ListingInfoPopup
                    v-for="l in payer.listings"
                    :key="l.id"
                    :listing="l"
                  >
                    <div
                      class="badge badge-secondary px-2 mr-1"
                    >
                      {{ l.name }}
                    </div>
                  </ListingInfoPopup>

                  <div
                    v-if="payer.listings.length === 0"
                    class="badge badge-danger px-2"
                  >
                    {{ $t('shop.offer.noMatchingOffer') }}
                  </div>
                </div>
              </template>
            </template>
          </SimplePayerRow>
        </div>
        <InfinityScrollCursor
          ref="cursor"
          @request="request"
        />
        <div v-if="busy">
          <Loader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import PayersGroupFilterDropdown from '@/components/payers/PayersGroupFilterDropdown';
import SimplePayerRow from '@/components/payers/SimplePayerRow';
import ListingInfoPopup from '@/components/shop/ListingInfoPopup';
import Checkbox from '@/components/utils/Checkbox';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    catalogId: String,
    productIds: Array,
    selectedListings: Array,
  },
  data: () => ({
    search: '',
    filter: [],
    groupFilter: '#all',
    bottom: false,
    busy: false,
    payers: [],
    offer: [],
    take: 50,
    skip: 0,
    requestId: 0,
    payersListHeight: 0,
    offerPending: false,
  }),
  components: {
    PayersGroupFilterDropdown,
    InfinityScrollCursor,
    ListingInfoPopup,
    SimplePayerRow,
    Checkbox,
  },
  computed: {
    ...mapGetters('shop', [
      'listings',
      'catalogDetails',
    ]),
    payersWithOffer() {
      return this.payers
        .map((p) => ({
          ...p,
          checked: this.selected.some((el) => el.id === p.id),
          listings: (this.offer.find((el) => el.payerId === p.id)?.listingIds || [])
            .map((id) => this.listings.find((l) => l.id === id)),
        }));
    },
    allToggled: {
      get() {
        return this.payers.length > 0 && this.payers.every((x) => this.selected.some((y) => x.id === y.id));
      },
      set() {
      },
    },
  },
  watch: {
    search() {
      this.skip = 0;
      this.bottom = false;
      this.busy = false;
      this.payers = [];
      this.requestId += 1;
      this.request();
    },
    groupFilter() {
      this.skip = 0;
      this.bottom = false;
      this.busy = false;
      this.payers = [];
      this.requestId += 1;
      this.request();
    },
    productIds() {
      this.reloadOffer();
    },
    selectedListings() {
      this.reloadOffer();
    },
  },
  methods: {
    ...mapActions([
      'getPayers',
    ]),
    ...mapActions('shop', [
      'findPayersOffer',
    ]),
    request(all) {
      if ((this.bottom || this.busy) && !all) return Promise.resolve();
      this.busy = true;
      this.error = null;
      const id = this.requestId;

      return this.getPayers({
        params: {
          query: {
            skip: all ? 0 : this.skip,
            take: all ? -1 : this.take,
            group: this.groupFilter === '#all'
              ? undefined
              : this.groupFilter,
            search: this.search.length === 0
              ? undefined
              : this.search,
          },
        },
      })
        .then(({ data }) => {
          if (all) {
            this.payers = data;
            this.bottom = true;
            this.busy = false;
            return;
          }

          if (id !== this.requestId) {
            return;
          }

          this.skip += this.take;

          const uniquePayers = data.filter((x) => !this.payers.some((y) => y.id === x.id));
          this.payers = [...this.payers, ...uniquePayers];

          if (data.length === 0) {
            this.bottom = true;
            this.busy = false;
          }
          setTimeout(() => {
            this.busy = false;
          }, 500);
        })
        .catch(() => {
          this.error = true;
        });
    },
    clearAll() {
      this.$emit('update:selected', []);
    },
    toggleAll(v) {
      if (v) {
        this.request(true)
          .then(() => {
            const toAdd = this.payers.filter((x) => !this.selected.some((y) => y.id === x.id));
            this.$emit('update:selected', this.selected.concat(toAdd));
            this.reloadOffer();
          });
      } else {
        const selected = this.selected.filter((x) => !this.payers.some((y) => y.id === x.id));
        this.$emit('update:selected', selected);
        this.reloadOffer();
      }
    },
    togglePayer(payer) {
      if (this.selected.some((x) => x.id === payer.id)) {
        this.$emit('update:selected', this.selected.filter((x) => x.id !== payer.id));
      } else {
        this.$emit('update:selected', this.selected.concat([payer]));
      }
      this.reloadOffer();
    },
    reloadOffer() {
      this.$nextTick(() => {
        if ((this.productIds.length === 0 && this.selectedListings.length === 0)
        || this.selected.length === 0) {
          this.offer = [];
          this.$emit('offer', []);
          return;
        }

        this.offerPending = true;
        this.findPayersOffer({
          params: {
            catalogId: this.catalogId,
          },
          data: {
            payerIds: this.selected.map((x) => x.id),
            productIds: this.productIds,
            listingIds: this.selectedListings,
          },
        })
          .then(({ data }) => {
            this.offer = data;
            this.$emit('offer', data);
            this.offerPending = false;
          })
          .catch(() => {
            this.offer = [];
            this.$emit('offer', []);
            this.offerPending = false;
          });
      });
    },
  },
  mounted() {
    this.updatePayersListHeight = () => {
      const payersList = this.$refs.payersList.getBoundingClientRect();
      this.payersListHeight = payersList.top;
    };
    this.updatePayersListHeight();
    window.addEventListener('resize', this.updatePayersListHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.updatePayersListHeight);
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>

  .info-row {
    border-bottom: 1px solid #dddddd;
    font-size: 12px;
    font-weight: 400;
    padding-top: 3px;
    padding-bottom: 7px;
  }

  .sm-label {
    font-size: 12px;
  }

  .selected-clients {
    box-shadow: 1px 3px 8px #ddd;
    border-radius: 10px;
    padding: 10px;
  }
  .clear-all {
    cursor: pointer;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
      color: $red !important;
    }
  }

  .selected-clients {

    .list-complete-item:hover {
      .close-btn {
        transition: color 250ms;
      }
    }

    .list-complete-item:hover {
      .close-btn {
        color: $red;
      }
    }
  }
</style>
