<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    max-width="800"
    placement="bottom"
  >
    <template #trigger>
      <div
        class="option selected-option position-relative"
        :class="{ modified }"
      >
        <slot
          name="option"
          :scope="selectedOption"
        >
          {{ selected }}
        </slot>
        <i class="fas fa-caret-down dropdown-caret" />
      </div>
    </template>

    <div
      v-for="o in options"
      :key="o.value"
      class="option select-option text-left"
      @click="select(o)"
    >
      <slot
        name="option"
        :scope="o"
      >
        {{ o.text }}
      </slot>
    </div>
  </Tippy>
</template>

<script>
export default {
  props: {
    options: Array,
    modified: Boolean,
    selected: [Number, String, Boolean],
  },
  computed: {
    selectedOption() {
      return this.options.find((o) => o.value === this.selected);
    },
  },
  methods: {
    select(k) {
      this.$emit('update:selected', k.value);
      this.$refs.popover.tippy().hide();
    },
  },
};
</script>

<style lang="scss" scoped>
  .option {
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 5px;

    &.select-option {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 1rem;
    }

    &.selected-option {
      background-color: #fafafa;
      width: 150px;
      height: 30px;
      display: flex;
      align-items: center;
      position: relative;

      &.modified {
        border: 1px solid $blue;
      }

      .dropdown-caret {
        position: absolute;
        right: 7px;
        top: 9px;
        z-index: 10;
        font-size: 11px;
        color: #aaa;
      }
    }

    &:hover {
      background-color: rgba(#aaa, 0.1);
    }
  }
</style>
