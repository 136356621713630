<template>
  <div class="d-flex flex-wrap justify-content-between position-relative px-2">
    <div class="d-flex pb-2">
      <div
        v-if="isShop"
        style="max-width: 200px; line-height: 1.2"
        class="small text-secondary d-flex align-items-center"
      >
        <i class="far fa-store mr-2" />
        <div>
          {{ $t('bill.shopBill') }}
        </div>
      </div>
      <div
        v-if="!isShop"
        class="pr-2"
      >
        <div
          class="secondary-icon bill-button px-3"
          :class="{ selected: action === 'edit' }"
          @click="$emit('update:action', 'edit')"
        >
          <i class="fas fa-money-bill-wave mr-2" />
          <span class="edit-details">
            {{ $t('bill.editPayers') }}
          </span>
        </div>
      </div>
      <div
        v-if="!isShop"
        class="pr-2"
      >
        <div
          class="secondary-icon bill-button px-3"
          :class="{ selected: action === 'add' }"
          @click="$emit('update:action', 'add')"
        >
          <i class="fas fa-plus mr-2" />
          <span class="edit-details">
            {{ $t('form.addPayers') }}
          </span>
        </div>
      </div>

      <div class="pr-2">
        <div
          class="secondary-icon bill-button px-3"
          :class="{ selected: action === 'details' }"
          @click="$emit('update:action', 'details')"
        >
          <i class="fas fa-pen-to-square mr-2" />
          <span class="edit-details">
            {{ $t('bill.editDetails') }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="!action"
      class="d-flex pb-2"
    >
      <div
        v-if="!isPlan"
        class="px-2"
      >
        <i
          v-tippy
          class="fas fa-download secondary-icon"
          :content="$t('general.export')"
          @click="downloadBill"
        />
      </div>

      <div
        v-if="!isShop"
        class="px-2"
      >
        <Tippy
          interactive
          arrow
          theme="light"
          trigger="click"
        >
          <template #trigger>
            <i
              v-tippy
              class="fas fa-trash secondary-icon"
              :content="$t('general.remove')"
            />
          </template>

          <template v-if="!blockDelete">
            <div
              v-if="!isPlan"
              class="font-weight-bold text-center"
            >
              {{ $t('bill.allPaymentsMovedToExcess') }}
            </div>
            <div v-if="!pendingRemove">
              <button
                class="btn btn-block btn-danger mt-3"
                data-test="remove-bill-confirm"
                @click="remove"
              >
                <i class="fas fa-trash pr-2" />
                {{ $t('general.confirmRemove') }}
              </button>
            </div>
            <div v-else>
              <Loader size="22px" />
            </div>
          </template>
          <template v-else>
            <div
              class="font-weight-bold text-center"
            >
              {{ $t('bill.cannotDeleteBill') }}
            </div>
          </template>
        </Tippy>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  props: {
    isPlan: Boolean,
    id: String,
    billTitle: String,
    action: String,
    color: String,
    externalId: String,
    blockDelete: Boolean,
  },
  data: () => ({
    pendingRemove: false,
    pendingDownload: false,
  }),
  computed: {
    isShop() {
      return this.externalId && this.externalId.startsWith('shop_');
    },
  },
  methods: {
    ...mapActions([
      'exportBill',
      'removeBill',
      'removeBillingPlan',
    ]),
    downloadBill() {
      this.pendingDownload = true;
      this.exportBill({
        params: {
          billId: this.id,
        },
      })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = `${this.billTitle}_${moment().format('DD_MM_YYYY')}`;
          link.setAttribute('download', `${fileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.pendingDownload = false;
        });
    },
    remove() {
      this.pendingRemove = true;
      if (!this.isPlan) {
        this.removeBill({
          params: {
            billId: this.id,
          },
        })
          .then(() => {
            this.$emit('removed');
            this.pendingRemove = false;
          })
          .catch(() => {
            this.pendingRemove = false;
          });
      } else {
        this.removeBillingPlan({
          params: {
            billingPlanId: this.id,
          },
        })
          .then(() => {
            this.$emit('removed');
            this.pendingRemove = false;
          })
          .catch(() => {
            this.pendingRemove = false;
          });
      }
    },
  },
  created() {
    if (this.showCompleted) {
      setTimeout(() => {
        this.completePopover = true;
      }, 500);
    }
  },
};
</script>

<style lang="scss" scoped>

  .control {
    padding: 10px 15px;
    height: 30px;
    border-radius: 20px;
    font-size: 13px;
    line-height: 1;
  }

  .remove-icon, .add-icon {
    color: #888;
    cursor: pointer;
  }

  .remove-icon:hover {
    color: $red;
  }
</style>
