<template>
  <div
    ref="wrapper"
    class="w-100"
  >
    <div class="d-flex align-items-center w-100">
      <div>
        <i
          v-if="!hideMonthSelect"
          class="fas fa-chevron-left mr-2 secondary-icon"
          @click="previousMonth"
        />
      </div>
      <div class="flex-grow-1 text-center month-name text-capitalize">
        {{ monthName }}
      </div>
      <div>
        <i
          v-if="!hideMonthSelect"
          class="fas fa-chevron-right mr-2 secondary-icon"
          @click="nextMonth"
        />
      </div>
    </div>
    <div
      class="d-flex align-items-center w-100 mt-4"
      :style="{
        width: `${boxSize * 7 + 1}px`,
      }"
    >
      <div
        v-for="wd in weekDays"
        :key="wd"
        class="weekday text-center"
        :style="{
          width: `${boxSize}px`,
        }"
      >
        {{ wd }}
      </div>
    </div>
    <div
      ref="gridWrapper"
      class="d-flex flex-wrap"
      :style="{
        width: `${boxSize * 7 + 1}px`,
      }"
    >
      <div
        v-for="d in days"
        :key="d.date"
        :ref="`day_${d.weekNumber}_${d.dayNumber}`"
        :style="{
          width: `${boxSize}px`,
          height: `${boxSize}px`
        }"
        class="day-box"
        :data-daynumber="d.dayNumber"
        :data-weeknumber="d.weekNumber"
        :class="{
          'other-month': d.month !== month,
          available: d.available,
          highlighted: highlightedDays.includes(d.date),
          'light-highlighted': unavailableDays.includes(d.date),
          selected: d.selected,
        }"
      >
        <div
          class="day"
          :class="{ compressed: compressed }"
        >
          <template v-if="dayStatus[d.day]">
            <span
              v-if="dayStatus[d.day].new"
              class="status status-new"
            >+{{ dayStatus[d.day].new }}</span>
            <span
              v-if="dayStatus[d.day].cancel"
              class="status status-cancel"
            >-{{ Math.abs(dayStatus[d.day].cancel) }}</span>
            <span
              v-if="dayStatus[d.day].waiting"
              class="status status-waiting"
              :class="dayStatus[d.day].paid ? 'waiting-and-paid' : ''"
            >{{ dayStatus[d.day].waiting }}</span>
            <span
              v-if="dayStatus[d.day].paid"
              class="status status-paid"
            >{{ dayStatus[d.day].paid }}</span>
          </template>
          <span
            v-if="d.month === month"
            class="day-number"
            :class="{
              'push-down': dayStatus[d.day] && (dayStatus[d.day].waiting || dayStatus[d.day].paid)
            }"
          >{{ d.number }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    availableDays: {
      type: Array,
      default: () => [],
    },
    selectedDays: {
      type: Array,
      default: () => [],
    },
    dayStatus: {
      type: Object,
      default: () => ({}),
    },
    multiSelect: Boolean,
    compressed: Boolean,
    hideMonthSelect: Boolean,
    initAt: String,
    singleDay: Boolean,
    allAvailable: Boolean,
    defaultWith: {
      type: Number,
      default: 350,
    },
  },
  data: () => ({
    year: moment().year(),
    month: moment().month(),
    highlightedDays: [],
    unavailableDays: [],
    boxSize: 0,
    mouseStart: null,
  }),
  computed: {
    monthName() {
      return moment().month(this.month).year(this.year).format('MMMM YYYY');
    },
    weekDays() {
      const weekDays = [0, 1, 2, 3, 4, 5, 6];
      return weekDays.map((x) => moment().weekday(x).format('ddd'));
    },
    days() {
      const curr = moment()
        .year(this.year)
        .month(this.month)
        .startOf('month')
        .startOf('week');

      const end = moment(curr)
        .add(1, 'month')
        .add(1, 'week')
        .startOf('week');

      if (end.isSameOrBefore(moment(curr).add(2, 'month').startOf('month'))) {
        end.add(1, 'week');
      }

      const days = [];
      let dayNumber = 0;

      while (curr.isBefore(end)) {
        days.push({
          available: this.availableDays.includes(curr.format('YYYY-MM-DD')) || this.allAvailable,
          selected: this.selectedDays.includes(curr.format('YYYY-MM-DD')),
          number: Number.parseInt(curr.format('D'), 10),
          month: curr.month(),
          dayNumber: dayNumber % 7,
          weekNumber: Math.floor(dayNumber / 7),
          date: curr.format(),
          day: curr.format('YYYY-MM-DD'),
        });
        dayNumber += 1;
        curr.add(1, 'day');
      }

      return days;
    },
  },
  methods: {
    previousMonth() {
      if (this.month === 0) {
        this.year -= 1;
        this.month = 11;
      } else {
        this.month -= 1;
      }

      this.emitDates();
    },
    nextMonth() {
      if (this.month === 11) {
        this.year += 1;
        this.month = 0;
      } else {
        this.month += 1;
      }

      this.emitDates();
    },
    clear() {
      this.emitDates();
    },
    emitDates() {
      if (this.highlightedDays.length > 0) {
        this.highlightedDays = [];
        this.$emit('highlighted', []);
      }

      this.unavailableDays = [];
      const from = `${this.year}-${(this.month + 1).toString().padStart(2, '0')}-01`;

      this.$emit('dates', {
        from,
        to: moment(from, 'YYYY-MM-DD').add(1, 'month').format('YYYY-MM-DD'),
      });
    },
    calculateWrapper() {
      if (!this.$refs.wrapper) return;
      const wrapper = this.$refs.wrapper.getBoundingClientRect();
      this.boxSize = Math.floor((wrapper.width || this.defaultWith) / 7);

      setTimeout(() => {
        const gridWrapper = this.$refs.gridWrapper.getBoundingClientRect();
        this.wrapperLeft = gridWrapper.left;
        this.wrapperTop = gridWrapper.top;
      }, 100);
    },
  },
  mounted() {
    if (this.initAt) {
      this.year = moment(this.initAt).year();
      this.month = moment(this.initAt).month();
    }

    this.emitDates();
    setTimeout(() => {
      this.calculateWrapper();
      window.addEventListener('resize', this.calculateWrapper);

      const highlightDays = (fromDay, fromWeek, toDay, toWeek, finish) => {
        const highlighted = [];
        const unavailable = [];

        this.days.forEach((d) => {
          const fromDayNumber = Math.min(fromDay, toDay);
          const toDayNumber = Math.max(fromDay, toDay);
          const fromWeekNumber = Math.min(fromWeek, toWeek);
          const toWeekNumber = Math.max(fromWeek, toWeek);
          const alreadyHighlighted = this.highlightedDays.indexOf(d.day) > -1;

          const highlight = (d.dayNumber >= fromDayNumber && d.dayNumber <= toDayNumber)
            && (d.weekNumber >= fromWeekNumber && d.weekNumber <= toWeekNumber);

          const r = this.$refs[`day_${d.weekNumber}_${d.dayNumber}`];
          if (!r || r.length === 0) {
            return;
          }

          let select = highlight;

          if (this.multiSelect) {
            select = (highlight && !alreadyHighlighted)
              || (!highlight && alreadyHighlighted);
          } else if (this.singleDay) {
            select = highlight && highlighted.length === 0;
          }

          if (select) {
            if (d.available && d.month === this.month) {
              highlighted.push(d);
              r[0].classList.add('highlighted');
            } else {
              unavailable.push(d);
              r[0].classList.add('light-highlighted');
            }
          } else {
            r[0].classList.remove('highlighted');
            r[0].classList.remove('light-highlighted');
          }
        });

        if (finish) {
          this.highlightedDays = highlighted.map((x) => x.day);
          this.unavailableDays = unavailable.map((x) => x.day);
        }
      };

      const mouseDown = (ev) => {
        const dayBox = ev.target.closest('.day-box');
        if (!dayBox || !dayBox.dataset.daynumber) {
          return;
        }

        ev.preventDefault();

        this.boxesX = 0;
        this.boxesY = 0;

        const clientX = ev.type === 'touchstart'
          ? ev.touches[0].clientX
          : ev.clientX;

        const clientY = ev.type === 'touchstart'
          ? ev.touches[0].clientY
          : ev.clientY;

        const wrapperX = (clientX - this.wrapperLeft);
        const wrapperY = (clientY - this.wrapperTop);

        const boxX = Math.ceil(wrapperX / this.boxSize);
        const boxY = Math.ceil(wrapperY / this.boxSize);

        this.mouseStart = {
          date: Number.parseInt(dayBox.dataset.date, 10),
          weekNumber: Number.parseInt(dayBox.dataset.weeknumber, 10),
          dayNumber: Number.parseInt(dayBox.dataset.daynumber, 10),
          boxX,
          boxY,
        };

        highlightDays(
          this.mouseStart.dayNumber,
          this.mouseStart.weekNumber,
          this.mouseStart.dayNumber,
          this.mouseStart.weekNumber,
        );
      };

      this.$refs.wrapper.addEventListener('mousedown', mouseDown);
      this.$refs.wrapper.addEventListener('touchstart', mouseDown);

      const mouseUp = () => {
        if (!this.mouseStart) {
          return;
        }

        highlightDays(
          this.mouseStart.dayNumber,
          this.mouseStart.weekNumber,
          this.mouseStart.dayNumber + this.boxesX,
          this.mouseStart.weekNumber + this.boxesY,
          true,
        );
        this.mouseStart = null;
        this.$emit('highlighted', this.highlightedDays);
        this.days.forEach((d) => {
          if (this.unavailableDays.includes(d.day)) {
            const r = this.$refs[`day_${d.weekNumber}_${d.dayNumber}`];
            if (r && r.length > 0) {
              r[0].classList.remove('light-highlighted');
            }
          }
        });

        this.unavailableDays = [];
      };

      document.body.addEventListener('mouseup', mouseUp);
      document.body.addEventListener('touchend', mouseUp);

      const mouseMove = (ev) => {
        if (!this.mouseStart) {
          return;
        }

        const clientX = ev.type === 'touchmove'
          ? ev.touches[0].clientX
          : ev.clientX;

        const clientY = ev.type === 'touchmove'
          ? ev.touches[0].clientY
          : ev.clientY;

        const wrapperX = (clientX - this.wrapperLeft);
        const wrapperY = (clientY - this.wrapperTop);

        const boxX = Math.ceil(wrapperX / this.boxSize);
        const boxY = Math.ceil(wrapperY / this.boxSize);

        this.boxesX = boxX - this.mouseStart.boxX;
        this.boxesY = boxY - this.mouseStart.boxY;

        highlightDays(
          this.mouseStart.dayNumber,
          this.mouseStart.weekNumber,
          this.mouseStart.dayNumber + this.boxesX,
          this.mouseStart.weekNumber + this.boxesY,
        );
      };

      document.body.addEventListener('mousemove', mouseMove);
      document.body.addEventListener('touchmove', mouseMove);
    }, 0);
  },
};
</script>

<style lang="scss" scoped>
.month-name {
  font-size: 16px;
  font-weight: 600;
}

.status {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 9px;
  font-weight: 500;
  color: white;
  border-radius: 50%;
}

.status-new {
  background-color: $blue;
  top: auto;
  bottom: 1px;
}

.status-cancel {
  background-color: #aaa;
  top: auto;
  right: auto;
  left: 1px;
  bottom: 1px;
}
.status-waiting {
  background-color: $orange;

  &.waiting-and-paid {
    right: 15px;
  }
}
.status-paid {
  background-color: $lightblue;
}

.weekday {
  color: #999;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 0.8rem;
}

.day {
  border: 2px solid transparent;
  background-color: #f7f7f7;
  position: relative;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #aaa;

  &.compressed {

    .status {
      top: -4px;
      right: -4px;
    }

    .day-number {
      font-size: 0.8rem;
    }
  }

  &:hover {
    border: 2px solid #eee;
  }
}

.day-box {
  padding: 2px;

  &.highlighted {
    .day {
      border: 2px solid $blue;
    }
  }
  &.light-highlighted {
    .day {
      border: 2px solid #aaa;
    }
  }
  &.selected {
    .day {
      background-color: rgba($blue, 0.2);
    }
  }

  &.available {
    .day {
      color: #333;
    }
  }

  &.other-month {
    .day {
      cursor: default;
      background-color: #fbfbfb;
    }
  }
}

</style>
