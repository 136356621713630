<template>
  <div
    class="h-100 d-flex flex-column"
    style="overflow: hidden"
  >
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header">
          <h1>
            {{ $t('shop.addOrders') }}
          </h1>
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="cancel"
          >
            ×
          </button>
        </div>
      </div>

      <hr class="mt-1">
      <div v-show="!summary">
        <div
          v-if="showCalendar"
          class="d-flex justify-content-between"
        >
          <div style="width: 100px" />
          <div style="height: 350px; width: 300px; ">
            <Calendar
              multi-select
              compressed
              :available-days="Object.keys(listingByDay)"
              :day-status="dayStatus"
              @highlighted="selectedDays = $event"
              @dates="requestCalendar"
            />
          </div>
          <div
            style="width: 200px"
            class="text-right"
          >
            <button
              class="btn btn-primary btn-lg next-btn mr-1"
              @click="summary = true"
            >
              {{ $t('general.next') }}
              <i class="fas fa-arrow-right px-2" />
            </button>
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-content-end mb-3"
        >
          <div
            style="width: 200px"
            class="text-right"
          >
            <button
              class="btn btn-primary btn-lg next-btn mr-1"
              @click="summary = true"
            >
              {{ $t('general.next') }}
              <i class="fas fa-arrow-right px-2" />
            </button>
          </div>
        </div>

        <hr v-if="showCalendar">
        <div class="row">
          <div class="col-7">
            <OrderPayersList
              :catalog-id="catalogId"
              :selected.sync="payers"
              :product-ids="selectedProducts"
              :selected-listings.sync="selectedListings"
              :style="{
                overflowY: 'auto',
                height: showCalendar ? 'calc(100vh - 510px)' : 'calc(100vh - 210px)' }"
              @offer="offer = $event"
            />
          </div>
          <div class="col-5">
            <ProductList
              :catalog-id="catalogId"
              :selected.sync="selectedProducts"
              :selected-listings.sync="selectedListings"
              :with-calendar="showCalendar"
            />
          </div>
        </div>
      </div>
      <template v-if="summary">
        <div class="d-flex justify-content-between">
          <div
            style="width: 200px"
            class="text-left"
          >
            <button
              class="btn btn-secondary btn-lg next-btn mr-1"
              @click="summary = false"
            >
              {{ $t('general.back') }}
              <i class="fas fa-arrow-left px-2" />
            </button>
          </div>
          <div
            style="width: 200px"
            class="text-right"
          >
            <button
              class="btn btn-primary btn-lg next-btn mr-1"
              :disabled="pending"
              @click="save"
            >
              {{ $t('general.save') }}
              <i class="fas fa-check px-2" />
            </button>
          </div>
        </div>
        <hr>
        <BTextarea
          v-model="comment"
          :placeholder="`${$t('shop.comment')}...`"
          class="px-2"
        />
        <hr>
        <OrderByDaySummary
          v-if="catalogDetails?.orderSchema === 'Daily'"
          :payers="payers"
          :selected-days="selectedDays"
          :calendar="calendar"
          :offer="offer"
          :order-schema="catalogDetails?.orderSchema"
          @orders="orders = $event"
        />
        <OrderSingleSummary
          v-else
          :payers="payers"
          :offer="offer"
          @orders="orders = $event"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Calendar from '@/modules/payer/components/shop/Calendar';
import { mapActions, mapGetters } from 'vuex';
import OrderByDaySummary from './OrderByDaySummary';
import OrderPayersList from './OrderPayersList';
import OrderSingleSummary from './OrderSingleSummary';
import ProductList from './ProductList';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    payers: [],
    selectedDays: [],
    selectedProducts: [],
    selectedListings: [],
    availableDays: [],
    summary: false,
    offer: [],
    calendar: [],
    orders: [],
    comment: '',
    pending: false,
  }),
  components: {
    Calendar,
    ProductList,
    OrderPayersList,
    OrderByDaySummary,
    OrderSingleSummary,
  },
  computed: {
    ...mapGetters('shop', [
      'catalogDetails',
    ]),
    showCalendar() {
      return this.catalogDetails && this.catalogDetails.orderSchema === 'Daily';
    },
    listingByDay() {
      return this.calendar
        .reduce((acc, curr) => {
          if (!acc[curr.day]) {
            acc[curr.day] = [];
          }
          acc[curr.day].push(curr.listingId);
          return acc;
        }, {});
    },
    dayStatus() {
      return Object.entries(this.listingByDay)
        .reduce((acc, [day, listingIds]) => {
          if (!this.selectedDays.includes(day)) {
            return acc;
          }

          const count = this.offer
            .flatMap((y) => y.listingIds)
            .filter((lid) => listingIds.includes(lid))
            .length;

          acc[day] = {
            // waiting: totalCount - count,
            paid: count,
          };

          return acc;
        }, {});
    },
  },
  methods: {
    ...mapActions('shop', [
      'getProducts',
      'getCatalogListings',
      'getShopCatalog',
      'getListingsCalendar',
      'updateOrders',
    ]),
    save() {
      this.pending = true;

      this.updateOrders({
        data: {
          catalogId: this.catalogId,
          addedItems: this.orders
            .map((x) => ({
              ...x,
              availabilityId: x.listingId,
              comment: this.comment,
            })),
        },
      })
        .then(() => {
          this.$router.back();
        })
        .finally(() => {
          this.pending = false;
        });
    },
    cancel() {
      this.$router.back();
    },
    requestCalendar({ from, to }) {
      this.getListingsCalendar({
        params: {
          catalogId: this.catalogId,
          query: {
            from,
            to,
          },
        },
      })
        .then(({ data }) => {
          this.calendar = data;
        });
    },
    request() {
      if (!this.listings || this.listings.length === 0) {
        this.getCatalogListings({
          params: {
            catalogId: this.catalogId,
          },
        });
      }
      if (!this.products || this.products.length === 0) {
        this.getProducts({
          params: {
            catalogId: this.catalogId,
          },
        });
      }

      if (!this.catalogDetails) {
        this.getShopCatalog({
          params: {
            catalogId: this.catalogId,
          },
        });
      }
    },
  },
  created() {
    this.request();
    this.$emit('page', 'form');
  },
};
</script>

<style lang="scss" scoped>
  .close {
    font-size: 40px;
  }
</style>
