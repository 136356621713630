<template>
  <div v-if="!subCatalog">
    <SubCatalogForm
      ref="createForm"
      :catalog-id="catalogId"
      @close="$emit('close')"
    />

    <hr>
    <div class="d-flex justify-content-between">
      <div />
      <div class="text-nowraptext-right">
        <button
          :disabled="pending"
          class="btn btn-primary btn-sm"
          @click="$refs.createForm.submit()"
        >
          <i class="fas fa-check pr-2" />
          <span>
            {{ $t('general.add') }}
          </span>
        </button>
      </div>
    </div>
  </div>
  <EditableList
    v-else
    ref="list"
    :list="options"
    :pending="pending"
    :form-add-title="$t('shop.subcatalogNode.add')"
    :form-edit-title="selected === subCatalog.subCatalogId
      ? $t('shop.subcatalog.edit') : $t('shop.subcatalogNode.edit')"
    :hide-list="!subCatalog"
    :hide-remove="!subCatalog"
    :hide-add="subCatalog && subCatalog.subCatalogId === '#groups'"
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
    @selected="selected = $event"
  >
    <template #list="{ list, toggle }">
      <div style="min-height: 500px">
        <div
          class="sub-catalog-row"
          :class="{ selected: !subCatalog || selected === subCatalog.subCatalogId }"
          @click="toggle(subCatalog.subCatalogId)"
        >
          {{ subCatalog?.name }}
        </div>
        <SubCatalogsList
          v-if="subCatalog.subCatalogId !== '#groups'"
          class="pl-4"
          :list="list"
          :read-only="readOnlyNodes"
          :toggle="readOnlyNodes ? () => {} : toggle"
          :selected="selected"
          :root-id="''"
          @orderUpdated="updateNodeOrder"
        />
        <div
          v-else
          class="text-center mt-4 small"
        >
          <div class="text-center h3 text-muted">
            <i class="far fa-users" />
          </div>
          <div class="text-muted">
            {{ $t('shop.subcatalog.groupEditInSettings') }}
          </div>
        </div>
        <div
          v-if="orderSaved"
          class="text-success mt-2 small text-center"
        >
          {{ $t('shop.orderChangeSaved') }}
        </div>
      </div>
    </template>

    <template #form="{edited}">
      <SubCatalogForm
        v-if="!subCatalog || edited === subCatalog.subCatalogId"
        ref="form"
        :sub-catalog="subCatalog"
        :catalog-id="catalogId"
        @close="$emit('close')"
      />
      <SubCatalogNodeForm
        v-else
        ref="form"
        :pending.sync="pending"
        :node="getNode(edited)"
        :nodes="nodes"
        :sub-catalog-name="subCatalog.name"
        :sub-catalog-id="subCatalog.subCatalogId"
        :catalog-id="catalogId"
        @close="$refs.list.close()"
      />
    </template>
  </EditableList>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import { mapActions } from 'vuex';
import SubCatalogForm from './SubCatalogForm';
import SubCatalogNodeForm from './SubCatalogNodeForm';
import SubCatalogsList from './SubCatalogsList';

export default {
  props: {
    catalogId: String,
    subCatalog: Object,
    readOnlyNodes: Boolean,
    nodes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    pending: false,
    selected: null,
    orderSaved: false,
  }),
  components: {
    EditableList,
    SubCatalogForm,
    SubCatalogsList,
    SubCatalogNodeForm,
  },
  computed: {
    options() {
      return this.nodes.map((x) => ({
        id: x.nodeId,
        name: x.name,
        parentCode: x.parentId,
        ordinal: x.ordinal,
      }));
    },
  },
  methods: {
    ...mapActions('shop', [
      'updateShopSubCatalogNodesOrder',
    ]),
    getNode(id) {
      return this.nodes.find((x) => x.nodeId === id);
    },
    updateNodeOrder({ parentId, order }) {
      this.updateShopSubCatalogNodesOrder({
        params: {
          catalogId: this.catalogId,
          subCatalogId: this.subCatalog.subCatalogId,
        },
        data: {
          parentId,
          orderedIds: Object.keys(order)
            .sort((a, b) => order[a] - order[b]),
        },
      })
        .then(() => {
          this.orderSaved = true;

          setTimeout(() => {
            this.orderSaved = false;
          }, 3000);
        });
    },
  },
  created() {
    setTimeout(() => {
      if (this.readOnlyNodes && this.$refs.list) {
        this.$refs.list.toggle(this.subCatalog.subCatalogId);
      }
    }, 200);
  },
};
</script>

<style lang="scss" scoped>
.sub-catalog-row  {
  padding: 0.3rem 0.6rem;
  border-radius: 10px;
  border: 2px solid #eee;
  background-color: #fafafa;
  margin-top: 6px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  transition: border-color 300ms, margin-right 300ms;

  &:hover {
    border-color: #999;
  }
  &.selected {
    margin-right: -20px;
    background-color: rgba($blue, 0.1);
    border-color: $darkblue;
  }
}

.close {
  font-size: 28px;
  padding-right: 2px;
  margin-top: -2px;
}
</style>
