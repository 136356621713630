<template>
  <div>
    <div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="$emit('close')"
      >
        ×
      </button>
    </div>

    <div class="pt-3 orders-header">
      <i class="far fa-boxes mr-1" />

      {{ orders.length }}x
      {{ $t('shop.orders') }}
    </div>

    <div
      v-for="c in columnsWithNames"
      :key="c.key"
      style="min-height: 25px"
      class="d-flex align-items-center my-2"
    >
      <div
        style="width: 180px"
        class="small text-secondary"
      >
        <i :class="c.icon" />
        {{ c.name }}
      </div>

      <!-- <span
        v-if="c.key === 'productSetName'"
        :class="c.key"
      >
        {{ order.set.name }}
      </span> -->

      <!-- <div
        v-else-if="c.key === 'day'"
        class="money-font"
      >
        {{ singleOrderSchema ? "" : order.formattedDay }}
      </div> -->

      <span
        v-if="c.key === 'status'"
        :class="c.key"
        class="status-name"
      >
        <OrderFieldDropdown
          :options="statusOptions"
          style="margin-left: -7px"
          :selected.sync="updatedData.orderStatusId"
          :modified="updatedData.orderStatusId !== initData.orderStatusId"
        >
          <template #option="{ scope }">
            <span
              v-if="scope"
              class="badge px-3"
              :class="scope.value ? 'text-white' : ''"
              :style="{ backgroundColor: scope.value ? colors(scope.color) : 'transparent' }"
            >
              {{ scope.text }}
            </span>
            <span v-else>
              {{ '-' }}
            </span>
          </template>
        </OrderFieldDropdown>
      </span>

      <!-- <span
        v-else-if="c.key === 'created'"
        :class="c.key"
      >
        {{ formatDate(order.created) }}
      </span> -->

      <div
        v-else-if="c.key === 'paid'"
        :class="c.key"
      >
        <OrderFieldDropdown
          :options="yesNoOptions"
          :selected.sync="updatedData.isPaid"
          :modified="updatedData.isPaid !== initData.isPaid"
          style="margin-left: -7px"
        >
          <template #option="{ scope }">
            <div
              v-if="scope && scope.value"
              class="text-success small text-uppercase"
            >
              <span
                class="fa-stack fa-1x"
                style="width: 18px"
              >
                <i class="far fa-circle-dollar fa-stack-1x" />
              </span>

              {{ $t('general.yes') }}
            </div>
            <div
              v-else-if="scope.value === false"
              class="text-danger small text-uppercase"
            >
              <span
                class="fa-stack fa-1x"
                style="width: 18px"
              >
                <i class="far fa-circle-dollar fa-stack-1x" />
                <i class="far fa-slash fa-stack-1x" />
              </span>
              <span>
                {{ $t('general.no') }}
              </span>
            </div>
            <div
              v-else
              class="small text-uppercase"
            >
              -
            </div>
          </template>
        </OrderFieldDropdown>
      </div>
      <div
        v-else-if="c.key === 'delivered'"
        :class="c.key"
      >
        <OrderFieldDropdown
          :options="yesNoOptions"
          :selected.sync="updatedData.delivered"
          :modified="updatedData.delivered !== initData.delivered"
          style="margin-left: -7px"
        >
          <template #option="{ scope }">
            <div
              v-if="scope.value"
              class="text-success small text-uppercase"
            >
              <span
                class="fa-stack fa-1x"
                style="width: 18px"
              >
                <i class="far fa-hand-holding-box fa-stack-1x" />
              </span>

              {{ $t('general.yes') }}
            </div>
            <div
              v-else-if="scope.value === false"
              class="text-danger small text-uppercase"
            >
              <span
                class="fa-stack fa-1x"
                style="width: 18px"
              >
                <i class="far fa-hand-holding-box fa-stack-1x" />
                <i class="far fa-slash fa-stack-1x" />
              </span>
              <span>
                {{ $t('general.no') }}
              </span>
            </div>
            <div
              v-else
              class="small text-uppercase"
            >
              -
            </div>
          </template>
        </OrderFieldDropdown>
      </div>
      <div
        v-else-if="c.key === 'count'"
        :class="c.key"
        class="money-font"
      >
        <OrderFieldText
          v-model="updatedData.count"
          :modified="updatedData.count !== initData.count"
          style="margin-left: -7px"
        />
      </div>
      <div
        v-else-if="c.key === 'price'"
        :class="c.key"
        class="money-font"
      >
        <OrderFieldText
          ref="unitPriceInput"
          v-model="updatedData.unitPrice"
          :modified="updatedData.unitPrice !== initData.unitPrice"
          style="margin-left: -7px"
        >
          {{ formatCurrency(updatedData.unitPrice, orders[0].currency) }}

          <template #edit>
            <MoneyInput
              v-model="updatedData.unitPrice"
              auto-select
              :currency="orders[0].currency"
              size="sm"
              class="money-input"
              @blur="moneyBlur"
            />
          </template>
        </OrderFieldText>
      </div>
      <div
        v-if="c.key === 'discount'"
        :class="c.key"
      >
        <OrderFieldText
          v-model="updatedData.discountName"
          :modified="updatedData.discountName !== initData.discountName"
          style="margin-left: -7px"
          expandable
        />
      </div>
      <div
        v-else-if="c.key === 'comment'"
        :class="c.key"
      >
        <OrderFieldText
          v-model="updatedData.comment"
          :modified="updatedData.comment !== initData.comment"
          style="margin-left: -7px"
          expandable
        />
      </div>
    </div>
    <hr>
    <div
      v-if="modified > 0"
      class="d-flex justify-content-end"
    >
      <button
        class="btn btn-sm bill-button btn-secondary mx-1"
        :disabled="pendingUpdate"
        @click="reset()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        class="btn btn-sm bill-button btn-primary mx-1"
        :disabled="pendingUpdate"
        @click="update"
      >
        {{ $t('general.save') }} ({{ orders.length }})
      </button>
    </div>
  </div>
</template>

<script>
import OrderFieldDropdown from '@/components/shop/report/table/OrderFieldDropdown';
import OrderFieldText from '@/components/shop/report/table/OrderFieldText';
import MoneyInput from '@/components/utils/MoneyInput';
import ordersColumns from '@/pages/shop/order/columns';
import colors from '@/utils/colors';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    orders: Array,
  },
  data: () => ({
    ordersColumns,
    updatedData: {},
    initData: {},
    pendingCancel: false,
    pendingUpdate: false,
  }),
  components: {
    OrderFieldDropdown,
    MoneyInput,
    OrderFieldText,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    ...mapGetters('shop', [
      'singleOrderSchema',
      'catalogDetails',
    ]),
    statusOptions() {
      return [
        {
          text: '-',
          value: null,
          color: '#aaa',
        },
        ...(this.catalogDetails.orderStatuses
          .map((s) => ({
            text: s.name,
            color: s.color,
            value: s.id,
          }))),
      ];
    },
    yesNoOptions() {
      return [
        {
          text: '-',
          value: null,
        },
        {
          text: this.$t('general.yes'),
          value: true,
        },
        {
          text: this.$t('general.no'),
          value: false,
        },
      ];
    },
    columnsWithNames() {
      const idColumns = ['productSetName', 'day', 'created'];

      return this.ordersColumns
        .filter((c) => !idColumns.includes(c.key))
        .filter((c) => c.key !== 'name' && c.key !== 'code')
        .filter((c) => c.key !== 'status' || this.catalogDetails.orderStatuses.length > 0);
    },
    modified() {
      return Object.keys(this.updatedData)
        .filter((k) => this.updatedData[k] !== this.initData[k])
        .length;
    },
  },
  methods: {
    ...mapActions('shop', [
      'updateOrderItems',
      'updateOrders',
    ]),
    moneyBlur() {
      if (this.$refs.unitPriceInput[0]) {
        this.$refs.unitPriceInput[0].blur();
      }
    },
    colors,
    update() {
      this.pendingUpdate = true;
      this.updateOrderItems({
        data: {
          items: this.orders.map((x) => ({
            payerId: x.payerId,
            orderId: x.orderId,
            date: x.day,
            offerId: x.availabilityId,
          })),
          statusId: this.updatedData.orderStatusId === this.initData.orderStatusId
            ? null : this.updatedData.orderStatusId,
          price: this.updatedData.unitPrice === this.initData.unitPrice
            ? null : this.updatedData.unitPrice,
          count: this.updatedData.count === this.initData.count
            ? null : this.updatedData.count,
          isPaid: this.updatedData.isPaid === this.initData.isPaid
            ? null : this.updatedData.isPaid,
          comment: this.updatedData.comment === this.initData.comment
            ? null : this.updatedData.comment,
          discount: this.updatedData.discountName === this.initData.discountName
            ? null : this.updatedData.discountName,
          delivered: this.updatedData.delivered === this.initData.delivered
            ? null : this.updatedData.delivered,
        },
      })
        .then(() => {
          this.orders.forEach((o) => {
            this.$emit('update', {
              ...o,
              orderStatusId: this.updatedData.orderStatusId === this.initData.orderStatusId
                ? o.orderStatusId : this.updatedData.orderStatusId,
              unitPrice: this.updatedData.unitPrice === this.initData.unitPrice
                ? o.unitPrice : this.updatedData.unitPrice,
              count: this.updatedData.count === this.initData.count
                ? o.count : this.updatedData.count,
              isPaid: this.updatedData.isPaid === this.initData.isPaid
                ? o.isPaid : this.updatedData.isPaid,
              comment: this.updatedData.comment === this.initData.comment
                ? o.comment : this.updatedData.comment,
              discountName: this.updatedData.discountName === this.initData.discountName
                ? o.discountName : this.updatedData.discountName,
              delivered: this.updatedData.delivered === this.initData.delivered
                ? o.delivered : this.updatedData.delivered,
            });
          });

          this.$emit('close');
          this.reset();
        })
        .finally(() => {
          this.pendingUpdate = false;
        });
    },
    formatDate(date) {
      return moment(date).format('DD MMM YYYY HH:mm');
    },
    reset() {
      this.initData = {
        isPaid: null,
        delivered: null,
        orderStatusId: null,
        unitPrice: null,
        count: null,
        comment: null,
        discountName: null,
      };

      this.updatedData = { ...this.initData };
    },
  },
  created() {
    this.reset();
  },
};
</script>

<style lang="scss" scoped>
  .date {
    font-size: 12px;
  }

  .close {
    margin-top: 10px;
    font-size: 30px;
    position: absolute;
    right: 20px;
  }

  .orders-header {
    font-size: 20px;
  }

  .set-row-hover:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }

  .offer-name {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
  }

  .set-row {
    border-bottom: 1px solid #ddd;
    padding: 2px 2px;
    cursor: pointer;
    height: 32px;
    align-items: center;

    &.no-padding {
      padding: 0px;
    }

    &.checkbox {
      border-right: 1px solid rgba(100, 100, 100, 0.2);
      display: flex;
      justify-content: center;
    }

    &.selected {
      background-color: rgba($blue, 0.2);
    }
  }

  .payer-icon {
    color: $light-gray;
  }

  .money-input {
    &:deep(input) {
      font-size: 14px;
      border-radius: 0;
      border: 0;
      background-color: transparent;
      padding: 0;
      height: 25.6px;
      text-align: left;

      &:focus {
        box-shadow: none;
      }
    }

    &:deep(.input-group-text) {
      border: none;
      background-color: transparent;
      position: relative;
      top: -1px;
    }
  }

  .bill-button {
    width: 150px;
  }
</style>
