<template>
  <div
    ref="wrapper"
    class="d-flex flex-column h-100"
  >
    <div
      class="pb-5"
    >
      <div class="d-flex justify-content-between align-items-center mt-2 action-bar">
        <div
          v-tippy
          class="clear-button"
          :content="$t('form.clearAll')"
          @click="clearAll"
        >
          <i class="fas fa-broom-wide" />
        </div>
        <div class="d-flex justify-content-end">
          <PayersGroupFilterDropdown
            ref="groups"
            v-tippy
            class="mr-2"
            :content="$t('groups.group')"
            @update:selected="addGroup"
          >
            <template #trigger>
              <div class="action-button">
                <i class="far fa-users" />
              </div>
            </template>
          </PayersGroupFilterDropdown>
          <PayersFilterDropdown
            ref="payers"
            :payer-ids="payerIds"
            :tooltip-parent="$refs.wrapper"
            add-only
            @selected="updatePayers"
          >
            <template #trigger>
              <div
                class="action-button"
                style="min-width: 250px;"
              >
                <div class="d-flex align-items-center">
                  <i class="fas fa-add ml-2 pr-1" />
                  <i class="fas fa-person pr-2" />
                  <div class="font-weight-bold">
                    {{ $tc('payers.payers', 1) }}
                  </div>
                </div>
              </div>
            </template>
          </PayersFilterDropdown>
        </div>
      </div>
      <div>
        <div
          v-for="g in selectedGroups"
          :key="g.code"
          class="payer-button d-flex align-items-center"
          :class="{ selected: selectedGroup && selectedGroup.code === g.code }"
          @click="selectedGroup = g; selectedPayer = null;"
        >
          <div
            class="close-btn"
            @click.stop="removeGroup(g.code)"
          >
            <i class="fas fa-times" />
          </div>
          <div class="flex-grow-1 font-weight-bold">
            <i class="far fa-users pr-2" />
            {{ g.name }}
            ({{ g.size }})
          </div>
          <div class="money-font">
            {{ formatCurrency(settlementSumByGroup[g.code] || 0, currency) }}
          </div>
        </div>
        <div
          v-for="payer in payers"
          :key="payer.id"
          class="payer-button d-flex align-items-center"
          :class="{ selected: selectedPayer && selectedPayer.id === payer.id }"
          @click="selectedPayer = payer; selectedGroup = null;"
        >
          <div
            class="close-btn"
            @click.stop="removePayer(payer)"
          >
            <i class="fas fa-times" />
          </div>
          <PayerName
            v-bind="payer"
            class="flex-grow-1"
          />
          <div class="money-font">
            {{ formatCurrency(settlementSumByPayer[payer.id] || 0, currency) }}
          </div>
        </div>
      </div>
      <hr>

      <PaymentPayerSettlementForm
        v-if="selectedPayer && suggested"
        :payer="selectedPayer"
        :to-settle="toSettle"
        :currency="currency"
        class="settlement-scroll"
        :outstanding="outstanding"
        @settlement="$set(selectedPayer, 'settlement', $event); emit()"
      />
      <PaymentGroupSettlementForm
        v-else-if="selectedGroup"
        :group="selectedGroup"
        :to-settle="toSettle"
        :currency="currency"
        class="settlement-scroll"
        @settlement="$set(selectedGroup, 'settlement', $event); emit()"
      />
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import PayersGroupFilterDropdown from '@/components/payers/PayersGroupFilterDropdown';
import { mapActions, mapGetters } from 'vuex';
import PaymentGroupSettlementForm from './PaymentGroupSettlementForm';
import PaymentPayerSettlementForm from './PaymentPayerSettlementForm';

export default {
  props: {
    paymentId: String,
    payment: Number,
    currency: String,
    date: String,
    autoSettle: Boolean,
    payers: Array,
    selectedGroups: Array,
  },
  data: () => ({
    outstanding: [],
    suggested: false,
    pending: false,
    selectedGroup: null,
    selectedPayer: null,
  }),
  components: {
    PayersFilterDropdown,
    PayersGroupFilterDropdown,
    PayerName,
    PaymentPayerSettlementForm,
    PaymentGroupSettlementForm,
  },
  computed: {
    ...mapGetters(['formatCurrency', 'groups']),
    settled() {
      const payersSum = (this.payers || [])
        .reduce((acc, curr) => acc + this.settlementSumByPayer[curr.id], 0);

      const groupsSum = (this.selectedGroups || [])
        .reduce((acc, curr) => acc + this.settlementSumByGroup[curr.code], 0);

      return payersSum + groupsSum;
    },
    settlementSumByPayer() {
      return (this.payers || [])
        .reduce((acc, curr) => {
          acc[curr.id] = this.sumSettlement(curr.settlement);
          return acc;
        }, {});
    },
    settlementSumByGroup() {
      return (this.selectedGroups || [])
        .reduce((acc, curr) => {
          acc[curr.code] = this.sumSettlement(curr.settlement) * curr.size;
          return acc;
        }, {});
    },
    payerIds() {
      return this.payers.map((x) => x.id);
    },
    toSettle() {
      return this.payment - this.settled;
    },
  },
  watch: {
    settled(v) {
      this.$emit('settled', v);
    },
    selectedPayer() {
      this.getPayerBills();
    },
  },
  methods: {
    ...mapActions(['getOutstandingBills']),
    removeGroup(id) {
      if (this.selectedGroup && this.selectedGroup.code === id) {
        this.selectedGroup = null;
      }

      const selectedGroups = this.selectedGroups.filter((x) => x.code !== id);
      this.$emit('update:selectedGroups', selectedGroups);
    },
    emit() {
      this.$emit('update:payers', this.payers);
      this.$emit('update:selectedGroups', this.selectedGroups);
    },
    addGroup(id) {
      if (this.selectedGroups.some((g) => g.code === id)) {
        if (this.selectedGroup && this.selectedGroup.code === id) {
          this.selectedGroup = null;
        }

        const selectedGroups = this.selectedGroups.filter((g) => g.code !== id);
        this.$emit('update:selectedGroups', selectedGroups);
      } else {
        const group = this.groups.find((x) => x.code === id);
        const selected = {
          ...group,
          name: group.code === '#all'
            ? this.$t('company.all')
            : group.name,
          settlement: {
            bills: [],
            overpayments: [],
            refunds: [],
            donations: [],
          },
        };

        this.$emit('update:selectedGroups', [...this.selectedGroups, selected]);
        this.selectedGroup = selected;
        this.selectedPayer = null;
      }
    },
    sumSettlement(settlement) {
      if (!settlement) return 0;

      return settlement.bills
        .concat(settlement.overpayments)
        .concat(settlement.refunds)
        .concat(settlement.donations)
        .reduce((acc, curr) => acc + curr.value, 0);
    },
    removePayer(payer) {
      const updatedPayers = this.payers.filter((x) => x.id !== payer.id);
      if (this.selectedPayer && this.selectedPayer.id === payer.id) {
        this.selectedPayer = null;
      }

      this.updatePayers(updatedPayers);
    },
    // getPayerBills(payerId) {
    //   return this.outstanding.filter((x) => x.payerId === payerId);
    // },
    updatePayers(payers) {
      const newPayers = payers
        .filter((x) => !this.payers.some((y) => y.id === x.id))
        .map((x) => ({
          ...x,
          settlement: {
            bills: [],
            overpayments: [],
            refunds: [],
            donations: [],
          },
        }));

      const updated = [...this.payers, ...newPayers]
        .filter((x) => payers.some((y) => y.id === x.id));

      this.$emit('update:payers', updated);

      if (newPayers.length > 0) {
        [this.selectedPayer] = newPayers;
        this.selectedGroup = null;
      }
    },
    clearAll() {
      this.selectedGroup = null;
      this.selectedPayer = null;
      this.$emit('update:payers', []);
      this.$emit('update:selectedGroups', []);
    },
    getPayerBills() {
      this.outstanding = [];
      if (!this.selectedPayer) return;

      this.suggested = false;
      this.getOutstandingBills({
        params: {
          query: {
            payerIds: [this.selectedPayer.id],
            payment: this.payment,
            currency: this.currency,
            billingDay: this.date,
            excludePaymentId: this.paymentId,
          },
        },
      })
        .then(({ data }) => {
          this.outstanding = data;

          if (this.autoSettle) {
            this.settleToOverpayments();
          }
        })
        .finally(() => {
          this.suggested = true;
        });
    },
    settleToOverpayments() {
      let remaining = this.payment - this.settled;
      if (remaining <= 0) return;

      this.payers.forEach((p) => {
        if (p.settlement.bills.length > 0
                || p.settlement.overpayments.length > 0
                || p.settlement.donations.length > 0
                || p.settlement.refunds.length > 0) {
          return;
        }

        this.outstanding
          .filter((y) => y.payerId === p.id)
          .forEach((x) => {
            const toPay = Math.min(remaining, Math.max(0, (x.amount - x.paid)));
            remaining -= toPay;

            p.settlement.bills.push({
              billId: x.billId,
              payerId: p.id,
              value: toPay,
            });
          });
      });

      this.emit();
    },
  },
  created() {
    if (this.payers.length > 0) {
      [this.selectedPayer] = this.payers;
    }
    this.$emit('settled', this.settled);
  },
  mounted() {
    if (this.payers.length === 0) {
      this.$refs.payers.open();
    }
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  padding: 2px 15px 2px 15px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  border: 1px solid #e8e8e8;

  &:hover {
   background-color: #e7e7e7;
  }
}

.clear-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #e8e8e8;

  &:hover {
    background-color: #e7e7e7;
  }
}

.payer-button {
  background-color: #fbfbfb;
  margin-top: 5px;
  padding: 3px 15px 3px 5px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;

  &:hover {
   background-color: #efefef;
  }

  &.selected {
    background-color: rgba($blue, 0.2);
  }
}

.action-bar {
  background-color: #fbfbfb;
  border-radius: 15px;
}

.close-btn {
  cursor: pointer;
  font-size: 20px;
  width: 24px;
  height: 24px;
  line-height: 25px;
  margin-right: 10px;
  text-align: center;
  border-radius: 50%;
  color: #888;
  background-color: #f6f6f6;

  &:hover {
    color: #333;
    background-color: #ddd;
  }
}

.settlement-scroll {
  overflow: auto;
  height: calc(100vh - 393px);
  padding-left: 15px;
}
</style>
