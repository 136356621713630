<template>
  <div>
    <div style="padding-bottom: 5.5px; margin-top: 3px">
      <SearchBox
        ref="search"
        v-model="search"
        constant-width
      />
    </div>
    <div
      style="height: 36px"
      class="d-flex"
    >
      <div
        class="secondary-icon bill-button w-50"
        :class="{ active: type === 'offer' }"
        @click="type = 'offer'"
      >
        {{ $t('shop.offer.offer') }}
      </div>
      <div
        class="secondary-icon bill-button w-50"
        :class="{ active: type === 'products' }"
        @click="type = 'products'"
      >
        {{ $t('shop.products') }}
      </div>
    </div>
    <div
      :style="{
        overflowY: 'auto',
        height: withCalendar ? 'calc(100vh - 620px)' : 'calc(100vh - 230px)'
      }"
    >
      <div
        v-if="type === 'products'"
        style="margin-top: 0px;"
      >
        <div
          v-for="s in filteredProducts"
          :key="s.id"
          class="sort-option text-left d-flex align-items-center pl-0"
          @click="toggle(s)"
        >
          <Checkbox
            :value.sync="checked[s.id]"
            :emit="false"
          />
          <div class="flex-grow-1 pl-2">
            {{ s.name }}
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="s in filteredListings"
          :key="s.id"
          class="sort-option text-left d-flex align-items-center pl-0"
          @click="toggleListing(s.id)"
        >
          <Checkbox
            :value.sync="checked[s.id]"
            :emit="false"
          />
          <div class="flex-grow-1 pl-2">
            {{ s.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/utils/Checkbox';
import createSearch from '@/utils/search';
import { mapGetters } from 'vuex';

export default {
  props: {
    catalogId: String,
    selected: Array,
    withCalendar: Boolean,
  },
  data: () => ({
    checked: {},
    type: 'offer',
    search: '',
  }),
  components: {
    Checkbox,
  },
  computed: {
    ...mapGetters('shop', [
      'products',
      'listings',
      'catalogDetails',
    ]),
    filteredListings() {
      if (!this.listings) return [];
      const search = createSearch(this.search);

      return this.listings
        .filter((x) => this.search.length === 0 || search(x.name));
    },
    filteredProducts() {
      if (!this.products) return [];
      const search = createSearch(this.search);

      return this.products
        .filter((x) => this.search.length === 0 || search(x.name));
    },
  },
  watch: {
    type() {
      this.checked = {};
      this.$emit('update:selected', []);
      this.$emit('update:selectedListings', []);
    },
  },
  methods: {
    toggle(s) {
      this.$set(this.checked, s.id, !this.checked[s.id]);
      this.$emit('update:selected', Object.keys(this.checked).filter((x) => this.checked[x]));
    },
    toggleListing(id) {
      this.$set(this.checked, id, !this.checked[id]);
      this.$emit('update:selectedListings', Object.keys(this.checked).filter((x) => this.checked[x]));
    },
  },
  created() {
    this.selected.forEach((x) => this.$set(this.checked, x, true));
  },
};
</script>

<style lang="scss" scoped>
 @import '~@/components/payers/style.scss';

  .bill-button {
    font-size: 14px;
    font-weight: 500;

    &.active {
      background-color: rgba($blue, 0.2);
      color: $blue;
    }
  }

  .sort-option-selected {
    position: relative;
    min-height: auto;
    padding: 10px;
    border-radius: 50%;
  }

  .close-btn {
    position: absolute;
    cursor: pointer;
    font-size: 20px;
    width: 24px;
    right: 10px;
    top: 11px;
    padding: 2px;
    text-align: center;
    border-radius: 50%;
    color: #888;

    &:hover {
      color: #333;
      background-color: #ddd;
    }
  }
</style>
