<template>
  <div>
    <div
      class="option selected-option position-relative"
      :class="{ modified, expandable, expanded: edit || (innerValue || '').length > 0 }"
      @click="startEdit"
    >
      <div
        class="pr-3 text-trauncate h-100"
      >
        <slot v-if="!edit">
          {{ innerValue }}
        </slot>
        <slot
          v-else
          name="edit"
        >
          <BFormInput
            v-if="!expandable"
            ref="input"
            v-model="innerValue"
            size="sm"
            @blur="save"
          />
          <BFormTextarea
            v-else
            ref="input"
            v-model="innerValue"
            size="sm"
            rows="1"
            max-rows="8"
            @blur="save"
          />
        </slot>
      </div>
      <i class="fas fa-pen dropdown-caret" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    modified: Boolean,
    value: [String, Number],
    expandable: Boolean,
  },
  data: () => ({
    edit: false,
    innerValue: '',
  }),
  watch: {
    innerValue(v) {
      this.$emit('input', v);
    },
  },
  methods: {
    select(k) {
      this.$emit('update:selected', k.value);
    },
    save() {
      this.edit = false;
    },
    blur() {
      this.edit = false;
    },
    startEdit() {
      this.edit = true;
      this.$emit('edit');
      this.$nextTick(() => {
        if (this.$refs.input) {
          this.$refs.input.select();
        }
      });
    },
  },
  created() {
    this.innerValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
  .option {
    padding: 2px 10px;
    cursor: text;
    border-radius: 5px;

    &.select-option {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 1rem;
    }

    &.selected-option {
      width: 150px;
      height: 30px;
      transition: width ease 300ms;
      background-color: #fafafa;

      &.modified {
        border: 1px solid $blue;
      }

      &.expandable {
        white-space: pre-line;
      }

      &.expandable.expanded {
        width: 250px;
        height: auto;
      }

      &.expandable.edited {
        width: 250px;
        height: auto;
      }

      input, textarea {
        font-size: 14px;
        border-radius: 0;
        border: 0;
        background-color: transparent;
        padding: 0;
        height: 25.6px;

        &:focus {
          box-shadow: none;
        }
      }

      .dropdown-caret {
        position: absolute;
        right: 7px;
        top: 9px;
        z-index: 10;
        font-size: 11px;
        color: #aaa;
      }
    }

    &:hover {
      background-color: rgba(#aaa, 0.1);
    }
  }
</style>
