<template>
  <EditableList
    ref="list"
    :list="options"
    :pending="pending"
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
    @selected="selected = $event"
  >
    <template #form="{edited}">
      <OrderStatusForm
        v-if="!orderStatus || edited === orderStatus.id"
        ref="form"
        :order-status="orderStatus"
        :pending.sync="pending"
        :catalog-id="catalogId"
        @close="$refs.list.close()"
      />
    </template>
  </EditableList>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import OrderStatusForm from './OrderStatusForm';

export default {
  props: {
    catalogId: String,
    statuses: Array,
  },
  data: () => ({
    pending: false,
    selected: null,
    orderSaved: false,
  }),
  components: {
    EditableList,
    OrderStatusForm,
  },
  computed: {
    options() {
      return this.statuses;
    },
    orderStatus() {
      return this.statuses.find((x) => x.id === this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-catalog-row  {
  padding: 0.3rem 0.6rem;
  border-radius: 10px;
  border: 2px solid #eee;
  background-color: #fafafa;
  margin-top: 6px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  transition: border-color 300ms, margin-right 300ms;

  &:hover {
    border-color: #999;
  }
  &.selected {
    margin-right: -20px;
    background-color: rgba($blue, 0.1);
    border-color: $darkblue;
  }
}

.close {
  font-size: 28px;
  padding-right: 2px;
  margin-top: -2px;
}
</style>
