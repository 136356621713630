var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header d-flex flex-wrap flex-md-nowrap"},_vm._l((_vm.columnsWithNames),function(c){return _c('div',{key:c.key,staticClass:"column-text",class:{
      'px-1': c.key !== 'space',
      'pr-2': c.key === 'price',
      [c.key]: true,
      [c.type]: true
    },style:(c.width
      ? { width:`${c.width}px`, minWidth: `${c.width}px` }
      : {})},[(c.key === 'space')?_c('span',[_vm._v("   ")]):_c('span',{staticClass:"text-nowrap"},[_c('i',{class:c.icon}),_vm._v(" "+_vm._s(c.name)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }