<template>
  <div class="h-100">
    <div class="page-title-wrapper">
      <div
        class="page-title"
        @click="$router.push(`/payee/${payeeId}/bills`)"
      >
        {{ $t('menu.bills') }}
      </div>
      <div
        class="page-title ml-4"
        @click="$router.push(`/payee/${payeeId}/bills/plans`)"
      >
        {{ $t('bill.plans') }}
      </div>
      <div
        class="active page-title ml-4"
      >
        {{ $t('menu.documents') }}
      </div>
    </div>

    <div
      class="bg-white"
      style="margin: 0 -20px; padding: 0 20px;"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <YearDropdown
            :selected.sync="year"
            class="mr-3"
          />
          <PayersFilterDropdown
            class="mr-3"
            @selected="selectedPayers = $event"
          />
        </div>
        <div>
          <div class="flex-grow-1 d-flex justify-content-end">
            <div>
              <button
                class="btn btn-sm btn-primary"
                @click="showBillingNotes()"
              >
                <i class="fas fa-plus pr-2" />
                {{ $t('accounting.generateBillingNotes') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-2">

      <div class="d-flex align-items-center pb-2">
        <div class="ml-2">
          <Tippy
            ref="download"
            :arrow="true"
            interactive
            theme="light"
            placement="bottom"
          >
            <template #trigger>
              <button
                class="btn btn-sm btn-primary"
                :disabled="exportPending || selectedDocuments.length === 0"
                @click.stop.prevent=""
              >
                <i class="fa-solid fa-download pr-2" />
                {{ $t('accounting.download') }}
              </button>
            </template>

            <div v-if="exportPending">
              <Loader />
            </div>
            <div
              v-else
              style="min-width: 180px"
            >
              <div
                class="sort-option text-left font-weight-bold"
                @click="requestExportDocuments('xlsx')"
              >
                <i class="far fa-file-excel pr-1" />
                Excel
              </div>
              <div
                class="sort-option text-left font-weight-bold"
                @click="requestExportDocuments('txt')"
              >
                <i class="far fa-file-lines pr-1" />
                Symfonia TXT
              </div>
              <div
                class="sort-option text-left font-weight-bold"
                @click="exportToZip"
              >
                <i class="far fa-file-zipper pr-1" />
                Zip
              </div>
            </div>
          </Tippy>
        </div>

        <div class="ml-2">
          <SendDocumentsButton
            :selected-documents="selectedDocuments"
          />
        </div>

        <div class="ml-3 small text-primary text-nowrap">
          {{ $t('accounting.selected') }}: {{ selectedDocuments.length }}
        </div>
      </div>

      <ColumnEdit
        :focused="focus"
        :edit="!!edited"
        class="h-100"
      >
        <div
          ref="wrapper"
          class="page-content"
        >
          <div
            class="documents-scroll"
            style="overflow-y: scroll; position: relative"
          >
            <BillingScopeList
              :billing-scopes="billingScopesWithDocuments"
            >
              <template #default="{ scope }">
                <div
                  v-if="scope.documents.length > 0"
                  class="d-flex align-items-center my-2 ml-2"
                >
                  <Checkbox
                    :value="scopeToggled[scope.id]"
                    class="pr-1 align-items-center"
                    @update:value="toggleAll(!scopeToggled[scope.id], scope.documents)"
                  >
                    <div class="mb-0 label sm-label">
                      {{ $t('general.selectAll') }}
                    </div>
                  </Checkbox>
                </div>

                <div
                  v-for="(doc, i) in scope.documents"
                  :key="doc.documentId"
                  class="document-row d-flex align-items-center"
                  :class="{ selected: edited && edited.documentId === doc.documentId }"
                >
                  <div
                    class="clickable"
                    @click.stop.prevent="select(doc, i, scope)"
                    @mouseenter="currentSelected = i; refreshSelect()"
                  >
                    <Checkbox
                      :value="selected[doc.documentId] || batchSelect[i]"
                      :emit="false"
                      :color="selected[doc.documentId] ? undefined : yellow"
                    />
                  </div>
                  <div
                    class="clickable d-flex align-items-center flex-grow-1"
                    @click="toggle(doc)"
                  >
                    <div class="document-id pl-3">
                      {{ doc.documentId }}
                    </div>
                    <div class="payer flex-grow-1">
                      <PayerName
                        v-if="doc.payer"
                        v-bind="doc.payer"
                      />
                      <div v-else>
                        ?
                      </div>
                    </div>
                  </div>
                  <div v-if="!edited">
                    <i
                      v-if="!downloadPending[doc.documentId]"
                      v-tippy
                      class="fas fa-download secondary-icon"
                      :content="$t('accounting.getPdf')"
                      @click="getAndDownloadDocument(doc.documentId)"
                    />
                    <Loader
                      v-else
                      size="20px"
                    />
                  </div>
                </div>
                <div
                  v-if="scope.documents.length === 0"
                  class="text-center text-secondary mt-3"
                >
                  <i class="fa-solid fa-empty-set" />
                  <div>
                    {{ $t('accounting.noDocuments') }}
                  </div>
                </div>
              </template>
            </BillingScopeList>
            <InfinityScrollCursor
              ref="cursor"
              @request="request"
            />
            <div
              class="text-center"
              style="height: 100px"
            >
              <Loader
                v-if="pending"
              />
            </div>
          </div>
        </div>
        <template #edit>
          <div
            v-if="edited"
            class="p-2 page-content"
          >
            <CloseButton
              style="position: absolute; right: 4px; top: 4px; z-index: 100"
              @click.native.stop="edited = null"
            />
            <div class="page-cart px-4 h-100 pt-4">
              <div class="d-flex">
                <div class="pr-2">
                  <div
                    v-if="details"
                    class="secondary-icon bill-button px-3"
                    @click.stop.prevent="downloadDocument(details.fileUrl, details.fileName)"
                  >
                    <i class="fas fa-download mr-2" />
                    <span class="edit-details">
                      {{ $t('accounting.getPdf') }}
                    </span>
                  </div>
                  <Loader
                    v-else
                    size="20px"
                  />
                </div>
              </div>
              <hr>
              <BillingDocumentPreview
                v-if="details"
                :file-preview-url="details.filePreviewUrl"
              />
            </div>
          </div>
        </template>
      </ColumnEdit>
    </div>
  </div>
</template>

<script>
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import YearDropdown from '@/components/YearDropdown';
import SendDocumentsButton from '@/components/accounting/SendDocumentsButton';
import BillingScopeList from '@/components/billingScopes/BillingScopeList';
import ColumnEdit from '@/components/layout/ColumnEdit';
import PayerName from '@/components/payers/PayerName';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import Checkbox from '@/components/utils/Checkbox';
import colors from '@/utils/colors';
import getFileName from '@/utils/file-name';
import moment from 'moment';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import BillingDocumentPreview from './BillingDocumentPreview';

const takeMonths = 1;

export default {
  data: () => ({
    year: moment().format('YYYY'),
    pending: false,
    focus: false,
    edited: null,
    documents: [],
    payers: {},
    billingScopes: [],
    haveAll: false,
    from: moment().startOf('month').subtract(takeMonths - 1, 'months'),
    to: moment().startOf('month').add(2, 'months'),
    selected: {},
    downloadPending: {},
    exportPending: false,
    selectedPayers: [],
    lastSelected: -1,
    currentSelected: -1,
    batchSelect: {},
    shiftKey: false,
    yellow: colors('yellow'),
    sendModal: false,
    details: null,
  }),
  components: {
    Checkbox,
    YearDropdown,
    ColumnEdit,
    PayerName,
    BillingScopeList,
    SendDocumentsButton,
    PayersFilterDropdown,
    InfinityScrollCursor,
    BillingDocumentPreview,
  },
  computed: {
    ...mapGetters(['payeeId']),
    selectedDocuments() {
      return Object.keys(this.selected).filter((x) => this.selected[x]);
    },
    scopeToggled() {
      return this.billingScopesWithDocuments.reduce((acc, curr) => {
        acc[curr.id] = curr.documents.every(({ documentId }) => this.selected[documentId]);
        return acc;
      }, {});
    },
    billingScopesWithDocuments() {
      return this.billingScopes
        .map((x) => ({
          ...x,
          fromMoment: moment(x.from, 'YYYY-MM-DD'),
          toMoment: moment(x.to, 'YYYY-MM-DD'),
        }))
        .map((x) => ({
          ...x,
          documents: this.documents
            .filter((b) => {
              const billingDay = moment(b.billingDay, 'YYYY-MM-DD');
              return billingDay.isSameOrAfter(x.fromMoment) && billingDay.isBefore(x.toMoment);
            })
            .filter((d) => this.selectedPayers.length === 0 || this.selectedPayers.some((p) => p.id === d.payerId))
            .map((d) => ({
              ...d,
              payer: this.payers[d.payerId],
            }))
            .sort((a, b) => b.documentId.localeCompare(a.documentId)),
        }));
    },
  },
  watch: {
    selectedPayers() {
      this.request();
    },
  },
  methods: {
    ...mapActions([
      'getDocuments',
      'getDocumentDetails',
      'getDocumentsZipped',
      'exportDocuments',
      'scheduleDocumentSend',
    ]),
    exportToZip() {
      this.selectedDocuments.forEach((id) => {
        this.$set(this.downloadPending, id, true);
      });
      this.getDocumentsZipped({
        data: {
          documentIds: this.selectedDocuments,
        },
      })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'documents.zip');
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.selectedDocuments.forEach((id) => {
            this.$set(this.downloadPending, id, false);
          });
        });
    },
    getAndDownloadDocument(documentId) {
      this.getDetails(documentId)
        .then(({ data }) => {
          this.downloadDocument(data.fileUrl, data.fileName);
        });
    },
    getDetails(documentId) {
      this.$set(this.downloadPending, documentId, true);
      return this.getDocumentDetails({
        params: {
          query: {
            documentId,
          },
        },
      })
        .finally(() => {
          this.$set(this.downloadPending, documentId, false);
        });
    },
    downloadDocument(fileUrl, fileName) {
      axios.get(fileUrl, {
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        });
    },
    select(doc, ix, scope) {
      if (this.shiftKey && this.lastSelected >= 0) {
        const from = Math.min(this.lastSelected, this.currentSelected);
        const to = Math.max(this.lastSelected, this.currentSelected);

        scope.documents.forEach((d, i) => {
          if (i > from && i <= to) {
            this.$set(this.selected, d.documentId, true);
          }
        });
      } else {
        this.$set(this.selected, doc.documentId, !this.selected[doc.documentId]);
      }

      if (this.selected[doc.documentId]) {
        this.lastSelected = ix;
        this.refreshSelect();
      }
    },
    toggle(doc) {
      this.details = null;
      if (this.edited && this.edited.documentId === doc.documentId) {
        this.edited = null;
      } else {
        this.edited = doc;
        this.getDetails(doc.documentId)
          .then(({ data }) => {
            this.details = data;
          });
      }
    },
    sendDocuments() {
      const documents = this.selectedDocuments
        .map((x) => this.documents.find((d) => d.documentId === x));

      if (documents.length === 0) {
        return;
      }

      const first = documents[0];

      this.scheduleDocumentSend({
        data: {
          payerIds: documents.map((x) => x.payerId),
          date: first.billingDay,
        },
      });
    },
    requestExportDocuments(format) {
      this.exportPending = true;

      this.exportDocuments({
        data: {
          documentIds: this.selectedDocuments,
          format,
        },
      })
        .then(({ data, headers }) => {
          if (this.$refs.download) {
            this.$refs.download.tippy().hide();
          }

          const name = getFileName(headers);
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          setTimeout(() => {
            this.exportPending = false;
          }, 500);
        });
    },
    showBillingNotes() {
      this.$router.push(`/payee/${this.payeeId}/bills/documents/form`);
    },
    toggleAll(value, documents) {
      documents.forEach(({ documentId }) => {
        this.$set(this.selected, documentId, value);
      });
    },
    request() {
      if (this.haveAll || this.pending) return;
      this.pending = true;

      this.getDocuments({
        params: {
          query: {
            from: this.from.format('YYYY-MM-DD'),
            to: this.to.format('YYYY-MM-DD'),
          },
        },
      })
        .then(({ data }) => {
          const oldest = moment(data.oldestBillingScope, 'YYYY-MM-DD');

          const uniqueDocuments = data.documents
            .filter((x) => !this.documents.some((y) => y.documentId === x.documentId));

          const uniqueScopes = data.scopes
            .filter((x) => !this.billingScopes.some((y) => y.id === x.id));

          this.documents = [...this.documents, ...uniqueDocuments];
          this.billingScopes = [...this.billingScopes, ...uniqueScopes];

          data.payers.forEach((p) => {
            this.$set(this.payers, p.id, p);
          });

          if (oldest.isBefore(this.from)) {
            this.from = this.from.subtract(takeMonths, 'month');
            this.to = this.to.subtract(takeMonths, 'month');
            setTimeout(() => {
              this.pending = false;
              if (!this.$refs.cursor || !this.$refs.cursor.isBottom()) {
                this.request();
              }
            }, 200);
          } else {
            this.pending = false;
            this.haveAll = true;
          }
        });
    },
    refreshSelect() {
      if (!this.shiftKey) {
        this.batchSelect = {};
      } else {
        this.batchSelect = {};
        let curr = this.lastSelected;

        while (curr !== this.currentSelected) {
          if (curr > this.currentSelected) {
            curr -= 1;
          } else {
            curr += 1;
          }

          this.$set(this.batchSelect, curr, true);
        }
      }
    },
  },
  created() {
    this.request();
    this.$emit('page', 'bills');

    document.addEventListener('keydown', (e) => {
      if (e.shiftKey) {
        this.shiftKey = true;
        this.refreshSelect();
      }
    });
    document.addEventListener('keyup', (e) => {
      if (!e.shiftKey) {
        this.shiftKey = false;
        this.refreshSelect();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
 @import '~@/components/payers/style.scss';

.documents-scroll {
  overflow: auto;
  height: calc(100vh - 190px);
}

.page-content {
  height: calc(100vh - #{$navbarHeight} - 137px);
  overflow-y: scroll;
  position: relative;
  &.focus {
    height: calc(100vh - #{$navbarHeight});
  }
}

.document-row {
  padding: 4px 15px 4px 3px;
  margin-top: 5px;
  height: 38px;
  background-color: #fafafa;

  .clickable {
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &.selected {
    background-color: rgba($blue, 0.1);

    .clickable:hover {
      background-color: rgba(#f0f0f0, 0.1);
    }
  }

  .document-id {
    width: 250px;
    font-weight: 500;
  }

  .payer {
    width: 260px;
    font-weight: normal;
  }
}
</style>
